var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filledBg",style:('min-height:' + this.$parent.heightAfterHeaderFooter + 'px'),attrs:{"id":"fullWidthContent"}},[_c('b-button',{staticClass:"backNav",attrs:{"size":"xs","variant":"link"},on:{"click":function($event){return _vm.$router.back()}}},[_c('b-icon-caret-left-fill')],1),_c('div',{staticClass:"margined"},[_c('h2',[_vm._v(_vm._s(_vm.collection.name))]),_c('b-form',[_c('b-form-group',{attrs:{"label":"Name:"}},[_c('b-form-input',{attrs:{"type":"text","required":""},model:{value:(_vm.collection.name),callback:function ($$v) {_vm.$set(_vm.collection, "name", $$v)},expression:"collection.name"}})],1),_c('b-form-group',{attrs:{"label":"Image URL:"}},[_c('b-form-input',{attrs:{"type":"text","required":""},model:{value:(_vm.collection.image),callback:function ($$v) {_vm.$set(_vm.collection, "image", $$v)},expression:"collection.image"}})],1),_c('b-form-group',{attrs:{"label":"Description:"}},[_c('b-form-textarea',{attrs:{"rows":"4","type":"text","required":""},model:{value:(_vm.collection.description),callback:function ($$v) {_vm.$set(_vm.collection, "description", $$v)},expression:"collection.description"}})],1),_c('div',{staticClass:"map"},[_c('GmapMap',{staticStyle:{"width":"100%","height":"350px"},attrs:{"options":{
						zoomControl:true,  
						mapTypeControl: false,
						scaleControl: true,
						streetViewControl: true,
						rotateControl: false,
						fullscreenControl: true,
						disableDefaultUi: false
					},"center":{ lat:parseFloat(_vm.collection.lat), lng:parseFloat(_vm.collection.lng) },"zoom":_vm.collection.mapZoom},on:{"zoom_changed":_vm.mapZoomed}},[_c('GmapMarker',{key:_vm.index,attrs:{"position":{ lat:parseFloat(_vm.collection.lat), lng:parseFloat(_vm.collection.lng) },"draggable":true},on:{"dragend":_vm.markerDragged}})],1)],1),_c('div',[_c('b-form-group',{attrs:{"id":"input-group-1","label-for":"enabled"}},[_c('b-form-checkbox',{attrs:{"name":"approved"},model:{value:(_vm.collection.enabled),callback:function ($$v) {_vm.$set(_vm.collection, "enabled", $$v)},expression:"collection.enabled"}},[_vm._v(" Enabled")])],1)],1)],1),_c('br'),_c('b-button',{on:{"click":_vm.save}},[_vm._v("Save")]),_vm._v(" "),(_vm.collection.id)?_c('b-button',{attrs:{"variant":"danger"},on:{"click":_vm.deleteCollection}},[_vm._v("Delete")]):_vm._e()],1),_c('b-modal',{attrs:{"id":"modalMessage","centered":"","title":"Create Area Guide","hide-header":"true"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"size":"","variant":"primary"},on:{"click":_vm.hideModal}},[_vm._v(" OK ")])]},proxy:true}])},[_c('h2',[_vm._v("Please fix the following:")]),_c('p',{staticClass:"my-4",domProps:{"innerHTML":_vm._s(_vm.message)}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }