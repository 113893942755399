import Vue from 'vue';
import VueCookies from 'vue-cookies';
import App from './App.vue';
import Login from './components/Login.vue';
import Logout from './components/Logout.vue';
import Register from './components/Register.vue';
import Welcome from './components/Welcome.vue';
import Activate from './components/Activate.vue';
import PasswordReset from './components/PasswordReset.vue';
import MemberHome from './components/MemberHome.vue';
import Chats from './components/Chats.vue';
import Chat from './components/Chat.vue';
import Member from './components/Member.vue';
import SearchPage from './components/SearchPage.vue';
import Home from './components/Home.vue';
import Members from './components/Members.vue';
import ChatsAdminPage from './components/ChatsAdminPage.vue';
import Profile from './components/Profile.vue';
import Listings from './components/Listings.vue';
import Listing from './components/Listing.vue';
import CreateListing from './components/CreateListing.vue';
import AreaGuidesAdmin from './components/AreaGuidesAdmin.vue';
import CollectionsAdmin from './components/CollectionsAdmin.vue';
import CollectionAdmin from './components/CollectionAdmin.vue';
import CollectionPage from './components/CollectionPage.vue';
import CollectionsPage from './components/CollectionsPage.vue';
import AreaGuideAdmin from './components/AreaGuideAdmin.vue';
import Forum from './components/Forum.vue';
import MapPage from './components/MapPage.vue';
import Privacy from './components/Privacy.vue';
import Support from './components/Support.vue';
import Router from 'vue-router';
import axios from 'axios';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueMeta from 'vue-meta';
import VueGtag from "vue-gtag";

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Router);
Vue.use(VueMeta);
Vue.use(axios);
Vue.use(VueCookies);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyD-19N-VjdTo9AejGrLyjKb2PIIfvwPE9w',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
  },
  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
})

Vue.config.productionTip = false;
Vue.prototype.$http = axios;

const router = new Router({
  scrollBehavior() {
    return { x: 0, y: -50 }
  },
  routes: [
    {
      path: '/activate', name: 'activate', component: Activate,
      meta: {
        hideNavbar: true,
      }
    },
    {
      path: '/passwordReset', name: 'passwordReset', component: PasswordReset,
      meta: {
        hideNavbar: true,
      }
    },
    { path: '/listings', name: 'listings', component: Listings },
    { 
      path: '/areaguidesadmin', name: 'areaguidesadmin', component: AreaGuidesAdmin,
      meta: {
        adminOnly: true
      }
    },
    { 
      path: '/collectionsadmin', name: 'collectionsadmin', component: CollectionsAdmin,
      meta: {
        adminOnly: true
      }
    },
    { 
      path: '/collectionadmin/:id', name: 'collectionadmin', component: CollectionAdmin,
      meta: {
        adminOnly: true,
        hideNavbarContent: true,
      }
    },
    { 
      path: '/collections/:id', name: 'collection', component: CollectionPage,
      meta: {
        authenticate: true
      }
    },
    { 
      path: '/forum', name: 'forum', component: Forum,
      meta: {
        authenticate: true,
        hideFooter: true
      }
    },
    { 
      path: '/map', name: 'map', component: MapPage,
      meta: {
        authenticate: true,
        hideFooter: true
      }
    },
    { 
      path: '/collections', name: 'collections', component: CollectionsPage,
      meta: {
        authenticate: true
      }
    },
    { 
      path: '/areaguideadmin/:id', name: 'areaguideadmin', component: AreaGuideAdmin,
      meta: {
        adminOnly: true,
        hideNavbarContent: true,
      }
    },
    {
      path: '/createlisting', name: 'createlisting', component: CreateListing,
      meta: {
        authenticate: true,
      }
    },
    {
      path: '/home', name: 'home', component: Home,
      meta: {
        authenticate: false,
        topBarColor: "#D7D1C4",
        largeLogo: true,
      }
    },
    {
      path: '/search', name: 'search', component:SearchPage,
      meta: {
        authenticate: true
      }
    },
    {
      path: '/editlisting/:id', name: 'editlisting', component: CreateListing,
      meta: {
        authenticate: true,
      }
    },
    {
      path: '/members/:id', name: 'member', component: Member,
      meta: {
        authenticate: true,
        hideNavbarContent: true,
      }
    },
    {
      path: '/memberhome', name: 'memberhome', component: MemberHome,
      meta: {
        authenticate: true,
      }
    },
    {
      path: '/chats', name: 'chats', component: Chats,
      meta: {
        authenticate: true,
      }
    },
    {
      path: '/privacy', name: 'privacy', component: Privacy,
      meta: {
        hideNavbarContent: true,
      }
    },
    {
      path: '/support', name: 'support', component: Support,
      meta: {
        hideNavbarContent: true,
      }
    },
    {
      path: '/chats/:id', name: 'chat', component: Chat,
      meta: {
        authenticate: true,
        hideFooter: true,
        hideNavbarContent: true,
      }
    },
    {
      path: '/profile', name: 'profile', component: Profile,
      meta: {
        authenticate: true,
      }
    },
    { path: '/members', name: 'members', component: Members,
      meta: {
        adminOnly: true,
      }
    },
    { path: '/chatsadmin', name: 'chatsadmin', component: ChatsAdminPage },
    { path: '/listings/:id', name: 'listing', component: Listing,
      meta: {
        hideNavbarContent: true,
        authenticate: true,
      } 
    },
    { path: '/login', name: 'login', component: Login,
      meta: {
        hideNavbarContent: true,
      }
    },
    { path: '/logout', name: 'logout', component: Logout,
      meta: {
        hideNavbarContent: true,
      }
    },
    {
      path: '/register', name: 'register', component: Register,
      meta: {
        hideNavbarContent: true,
        disableHomeLink:true,
        fullWidth: true
      }
    },
    {
      path: '/', name: 'welcome', component: Welcome,
      meta: {
        hideNavbar: true,
        hideFooter: true,
        fullWidth: true
      }
    },
    {
      path: '/about', name: 'about', component: Welcome,
      meta: {
        hideNavbar: true,
        hideFooter: true,
        fullWidth: true
      }
    },
    {
      path: '/howitworks', name: 'howitworks', component: Welcome,
      meta: {
        hideNavbar: true,
        hideFooter: true,
        fullWidth: true
      }
    },
    //{ path: '/', name: 'listings', component: Listings }
  ]
});

Vue.use(router);
Vue.use(VueGtag, {config: {id: "G-1X3S861H8J"}}, router);

fetch(process.env.BASE_URL + "config.json")
  .then((response) => response.json())
  .then((config) => {
    Vue.prototype.$config = config
    new Vue({
      data() {
        return{
          token: String,
        }
      },
      watch: {
        '$route': function() {
          if (this.$cookies.get('token')) {
            this.token = this.$cookies.get('token');
            this.$route.meta.admin = this.checkIsAdminOrEditor();
            if (this.$route.meta.adminOnly)
            {
              if (!this.$route.meta.admin) {
                this.$router.push("/login?route=" + this.$route.path);
              }
            }
          }
          else {
            if (this.$route.meta.adminOnly){
              this.$router.push("/login?route=" + this.$route.path);
            }
            if(this.$route.meta.authenticate){
              this.$router.push("/login?route=" + this.$route.path);
            }
          }
        }
      },
      methods: {
        parseJwt: function(token) {
          var base64Url = token.split('.')[1];
          var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
          var jsonPayload = decodeURIComponent(Buffer.from(base64, "base64").toString("ascii").split("").map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          }).join(''));
          return JSON.parse(jsonPayload);
        },
        checkIsAdminOrEditor: function() {
          var t = this.parseJwt(this.token);
          var admin = t["custom:admin"] != null && t["custom:admin"] == "true";
          var editor = t["custom:editor"] != null && t["custom:editor"] == "true";
          return admin || editor;
        }
      },
      router,
      render: (h) => h(App)
    }).$mount("#app")
  })

