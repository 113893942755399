<template>
<div id="fullWidthContent" class="filledBg">

	<b-button class="backNav" @click="$router.back()" size="xs" variant="link"><b-icon-caret-left-fill /></b-button>
	
	<div class="map" v-if="places && getListings">
			<GmapMap :options="{
				zoomControl:true,  
				mapTypeControl: false,
				scaleControl: true,
				streetViewControl: true,
				rotateControl: false,
				fullscreenControl: true,
				disableDefaultUi: false
			}" 
				:center="{ lat:51, lng:-0.12 }"
				:zoom="3"
				:style="'width: 100%; height: ' + this.$parent.heightAfterHeader + 'px'" 
				>
				<GmapMarker v-for="l in listings" :key="l.id" 
					:position="{ lat:parseFloat(l.property.address.lat), lng:parseFloat(l.property.address.lng) }"
					:draggable=false
					@click="propTapped"
				/>
				<GmapMarker v-for="p in places" :key="p.id" 
					:position="{ lat:parseFloat(p.lat), lng:parseFloat(p.lng) }"
					:draggable=false
					:icon="{ url: require('../assets/period.png')}"
					@click="placeTapped"
					:tag="p.id"
				/>
			</GmapMap>
		</div>

		<div class="place" v-if="selectedPlace">
			<b-row>
				<b-col>
					<div class="center">
						<b-button @click="selectedPlace = null" class="swipeClose" size="xs" variant="outline-dark"></b-button>
					</div>
				</b-col>
			</b-row>
			<b-row>
				<b-col v-if="selectedPlace.imageUri" cols="5"><b-img class="placeImage" :src="selectedPlace.imageUri"></b-img></b-col>
				<b-col>
					<div class="placeName">
						{{ selectedPlace.name }}
					</div>
					<div class="placeDesc">
						{{ selectedPlace.area }}<br/>
						{{ selectedPlace.bestFor }}
					</div>
					<div v-if="selectedPlace.url" class="placeLink"><a v-b-modal="'linkModal'" @click="linkTitle=selectedPlace.name; linkUrl=selectedPlace.url"><b-icon icon="globe-2" /></a></div>
				</b-col>
			</b-row>
		</div>

		<div class="place" v-if="selectedProperty">
			<b-row>
				<b-col>
					<div class="center">
						<b-button @click="selectedProperty = null" class="swipeClose" size="xs" variant="outline-dark"></b-button>
					</div>
				</b-col>
			</b-row>
			<b-row>
				<b-col cols="5"><b-img class="placeImage" :src="selectedProperty.property.photos[0].uri"></b-img></b-col>
				<b-col>
					<div class="placeName">
						{{ selectedProperty.property.address.oneLinerV2 }}
					</div>
					<div class="placeDesc">
						{{ selectedProperty.property.propertyTypeString }}<br/>
						{{ (selectedProperty.forRent && selectedProperty.forSwap ? 'Rental & Swaps' : (selectedProperty.forRent ? 'Rental' : 'Swaps')) }}<br/>
						{{ selectedProperty.property.bedrooms }} bedroom(s)
					</div>
					<div class="placeLink"><a :href="'/#/listings/' + selectedProperty.id"><b-icon icon="eye-fill" /></a></div>
				</b-col>
			</b-row>
		</div>

		<WebPopup :Title="linkTitle" :LinkURL="linkUrl" />
	
</div>
</template>
<script>

import WebPopup from './WebPopup.vue'

export default {
  name: 'MapPage',
	metaInfo() {
		return { 
			title: "Three Degrees - Map",
		}
	},
	components: {
		WebPopup
	},
	data() {
		return{
			places: null,
			listings: null,
			selectedProperty:null,
			selectedPlace:null,
			height: this.$route.meta.height - 190,
			linkUrl: null,
			linkTitle:null
		}
	},
  methods: {
	placeTapped: function(m) {
		var place = this.findPlaceByLatLng(m.latLng.lat(), m.latLng.lng());
		if (place) {
			this.selectedProperty = null;
			this.selectedPlace = place;
		}
	},
	propTapped: function(m) {
		var prop = this.findPropByLatLng(m.latLng.lat(), m.latLng.lng());
		if (prop) {
			this.selectedPlace = null;
			this.selectedProperty = prop;
		}
	},
	findPlaceByLatLng: function(lat, lng) {
		//alert(lat);
		for (var x = 0; x < this.places.length; x++) {
			//alert (this.places[x].lat);
			if (this.places[x].lat == lat && this.places[x].lng == lng) {
				return this.places[x];
			}
		}
		return null;
	},
	findPropByLatLng: function(lat, lng) {
		//alert(lat);
		for (var x = 0; x < this.listings.length; x++) {
			//alert (this.places[x].lat);
			if (this.listings[x].property.address.lat == lat && this.listings[x].property.address.lng == lng) {
				return this.listings[x];
			}
		}
		return null;
	},
	getPlaces: function(){
		this.$http.get(this.$config.ApiUri + "/AreaGuides/Places")
			.then((result) => {
				this.places = result.data;
			}
		)
	},
	getListings: function(){
		this.$http.get(this.$config.ApiUri + "/Listings?limit=9999999")
			.then((result) => {
				this.listings = result.data;
			}
		)
	},
	onLoad() {
		this.getPlaces();
		this.getListings();
	}
  },
  mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

.center
{
	text-align: center;
}

.swipeClose 
{
	width: 20px; height: 0px; padding:1px; 
	margin-bottom: 15px;
	border: 1px solid rgb(153, 153, 153);
	background-color: rgb(153, 153, 153);
}

.btn-link
{
	color: #000000 !important;
}

#fullWidthContent
{
	padding-top: 0px !important;
	padding-bottom: 0px !important;
	min-height: unset;
}

.place {
	background-color: #ffffff;
	color: black;
	padding: 10px;
	font-size: 10pt;
	position:fixed;
	bottom: 0px;
	width: 100%;
	max-width: 1000px;
}

.placeImage{
	width: 100%;
	border-radius: 3px;
	max-height: 400px;
}

.placeName {
	
	font-weight: bold;
}

.placeLink {
	font-size: 14pt;
}
</style>