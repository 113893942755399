<template>
<div id="fullWidthContent" class="filledBg">

	<div class="margined" v-if="messages">
		<b-form-input placeholder="search" class="mb-2" v-model="search"></b-form-input>
		{{ messages.length }} messages
		<b-table striped hover :filter="search" :items="messages" :fields="[
			{key: 'createDateTimeString', label: 'Sent'},
			{key: 'subject', label: 'Subject'},
			{key: 'from.person.email', label: 'From'},
			{key: 'to.person.email', label: 'To'},
			{key: 'subject', label: 'Subject'},
			{key: 'body', label: 'Message'},
		]" selectable select-mode="single">
		</b-table>
	</div>

</div>
</template>
<script>
export default {
  name: 'ChatsAdmin',
  metaInfo() {
        return { 
            title: "Three Degrees Admin - Chats",
        }
    },
  components: {
  },
  data() {
	return{
		messages: null,
		search: null
	}
  },
  methods: {
	getMessages: function(){
		this.$http.get(this.$config.ApiUri + "/Chats")
			.then((result) => {
				this.messages = result.data;
			}
		)
	},
	onLoad() {
		this.getMessages();
	},
  },
  mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

.mu-plus-icon
{
	color: #333333 !important;
}

</style>