<template>
<div id="fullWidthContent" class="filledBg">
	
	<b-button class="backNav" @click="$router.back()" size="xs" variant="link"><b-icon-caret-left-fill /></b-button>

	<div v-if="member" class="centered" style="margin-top:-10px">
		<div v-if="member.profilePicture && member.profilePicture != ''" class="profile" :style="'background-image: url(' + member.profilePicture + ')'" :text="member.person.firstName[0] + member.person.lastName[0]" size="5rem">
			<h2>{{member.person.firstName}} {{member.person.lastName}}</h2>
		</div>
		
		<h2 class="margined">About Me</h2>
		<p class="margined">{{ member.person.bio }}</p>
		<p class="margined" v-if="member.person.linkedIn"><b-icon icon="linkedin"></b-icon> <a target="_blank" :href="member.person.linkedIn">{{ member.person.firstName }} {{ member.person.lastName }}</a></p>
		<p class="margined" v-if="member.person.insta"><b-icon icon="instagram"></b-icon> <a target="_blank" :href="'https://www.instagram.com/' + member.person.insta">{{ member.person.firstName }} {{ member.person.lastName }}</a></p>
		<p class="margined" v-if="member.person.website"><b-icon icon="link"></b-icon> <a target="_blank" :href="member.person.website">{{ member.person.website }}</a></p>
		
		<div v-if="contacts && contacts.length > 0">
			<h2 class="margined">Members in Common</h2>
			<div class="margined" style="text-align: left;">
				<a :href="'/#/members/' + c.id" v-for="c in contacts" :key="c.id"> 
					<b-avatar class="mt-1" :src="c.profilePicture" :text="c.person.firstName[0] + c.person.lastName[0]" size="3rem"></b-avatar>&nbsp;
				</a>
			</div>
		</div>

	</div>

	<h2 v-if="listings && listings.length > 0" class="margined mb-0 more-margin-top">My Spaces</h2>
	<!-- <div v-if="listings" class="margined centered more-margin-top">
		<b-button v-b-toggle.collapse-my-listings variant="outline-primary">{{member.person.firstName}}'s Spaces <b-icon icon="arrow-down-short" /></b-button>
	</div> -->

	<a :href="'/#/listings/' + l.id" v-for="l in listings" :key="l.id">
		<ListingResult :Listing="l" Lite="true" :ShowAvailability=false />
	</a>

</div>
</template>
<script>
import ListingResult from './ListingResult.vue'

export default {
  name: 'Member',
  metaInfo() {
        return { 
            title: "Three Degrees - Member Profile",
        }
    },
  components: {
	ListingResult
  },
  data() {
	return{
		listings: null,
		contacts: null,
		member: null
	}
  },
  methods: {
	getMember: function(){
		this.$http.get(this.$config.ApiUri + "/Customers/" + this.$route.params.id)
			.then((result) => {
				this.member = result.data;
				this.getCommonContacts();
			}
		)
	},
	getCommonContacts: function() {
		this.$http.get(this.$config.ApiUri + "/Customers/00000000-0000-0000-0000-000000000000/Contacts?inCommonWith=" + this.$route.params.id)
			.then((result) => {
				this.contacts = result.data;
			}
		)
	},
	getMemberListings: function(){
		this.$http.get(this.$config.ApiUri + "/Customers/" + this.$route.params.id + "/Listings?onlyApproved=true")
			.then((result) => {
				this.listings = result.data;
			}
		)
	},
	onLoad() {
		this.getMember();
		this.getMemberListings();
	}
  },
  mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

.profile {
	height: 500px;
	background-position: center;
}

h2,p {
	text-align: left;
}

.profile h2{
	padding-top: 450px;
	padding-left: 20px;
	color: white;
	text-align: left;
}

.btn-link
{
	color: #000000 !important;
}

.badge-secondary {
    color: #fff;
    background-color: #b6b1a6;
}
	
</style>