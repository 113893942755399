<template>
	<div id="fullWidthContent" :style="'min-height:' + this.$parent.heightAfterHeaderFooter + 'px'">
		<b-button class="backNav" href="/#/home" size="xs" variant="link"><b-icon-caret-left-fill /></b-button>
		<!-- <b-img v-if="!done && !enterCode" class="hero" :src="require('@/assets/Image2.png')" /> -->
		<b-form class="margined" v-if="!canGetSelf && show">
			<h2>Login To Your Three Degrees Account</h2>
			<b-form-group id="input-group-1" label="Email:" label-for="txtFirstName" description="">
				<b-form-input id="txtFirstName" v-model="username" type="email" placeholder="" required></b-form-input>
			</b-form-group>
			<b-form-group id="input-group-1" label="Password:" label-for="txtLastName" description="">
				<b-form-input id="txtLastName" v-model="password" type="password" required></b-form-input>
			</b-form-group>
			<b-alert v-if="!loginOk" variant="danger" show><center>Login failed, please check your username and password.  If you have forgotten your password you can <a class="underlined" href="#/passwordReset">reset your password.</a></center></b-alert>
			<div class="margined centered more-margin-top">
				<b-button @click="login" variant="outline-primary">Login</b-button>
			</div>
			<div class="margined centered margin-top more-margin-bottom">
				<p>Want to join?</p>
				<b-button href="/#/register" variant="outline-primary">Apply</b-button>
			</div>
		</b-form>
	</div>
</template>

<script>

import Services from '@/mixins/Services'

export default {
	name: 'Login',
	mixins:[Services],
	metaInfo() {
        return { 
            title: "Three Degrees - Login",
        }
    },
	data() {
	return{
			username: "",
			password: "",
			show: false
		}
	},
	methods: {
		login: function() {
			this._login(this.username, this.password, () => {
				if (!this.$route.query.route) {
					this.$router.push('/memberhome');
				}
				else {
					this.$router.push(this.$route.query.route);
				}
			});
		},
		getSelf() {
			this._getSelf('home');
			this._tryLoginFromCookies(
				() => {
					if (!this.$route.query.route) {
						this.$router.push('/memberhome');
					}
					else {
						this.$router.push(this.$route.query.route);
					}
				}, 
				() => { 
					this.show = true; 
				}
			);
		}
	},
	mounted: function() {
		this.getSelf();
	}
}
</script>


<style scoped>

.btn-link
{
	color: #000000 !important;
}

</style>