<template>
	
	<div class="listingResult" >
		<div class="listing-title" v-if="Listing.property.address">
			<div>{{ Listing.property.address.oneLinerV2 }}</div>
		</div>
		<!-- <div class="listing-subtitle">
			<div>{{ Listing.property.bedrooms }} bedrooms, {{ Listing.property.bathrooms }} bathrooms</div>
		</div> -->
		<!-- TODO: Set the label as the Listing.property.title and fallback to listing.property.address.oneLinerV2 (TODO) if null -->
		<!-- <div class="info-smaller rounded-top" v-if="Listing.property.address && Listing.property.address.town && Listing.property.address.town != ''">
			<div><h3>{{ Listing.property.address.town }}</h3></div>
		</div> -->
		<!-- <b-img class="property-photo" v-if="Listing.property.photos[0]" fluid :src="Listing.property.photos[0].uri" /> -->

		<b-carousel
			class="property-photo"
			id="carousel-1"
			:interval="0"
			:indicators="true"
			controls
			background="#ffffff"
			img-width="1024"
			img-height="480"
		>
			<b-carousel-slide v-for="p in Listing.property.photos" :key="p.id" :img-src="p.uri"></b-carousel-slide>
		</b-carousel>

		<!-- <div :class="'priceInfo ' + (index % 2 == 0 ? '' : 'alt')">{{ Listing.pricing.priceString }} <span v-if="ShowAvailaibility" class="availability">({{ Listing.availability.available ? 'available' : 'unavailable' }})</span></div>
		<div class="info" v-if="!Lite">
			<div v-if="Listing.property.address" class="address">{{ Listing.property.address.oneLiner }}</div>
		</div> -->
		<div class="info"> 
			<span class="feature"><img :src="require('@/assets/bed.png')" width="24px" height="24px"/> {{ Listing.property.bedrooms }}</span>
			<span class="feature" v-if="Listing.property.bathrooms > 0"><img :src="require('@/assets/bath.png')" width="24px" height="24px"/> {{ Listing.property.bathrooms }}</span>
			<span v-if="Member && Favourite==false" class="feature"><b-icon-suit-heart /></span>
			<span v-if="Member && Favourite==true" class="feature"><b-icon-suit-heart-fill /></span>
			<span class="float-right">{{Listing.pricing.priceString}}</span>
		</div>
		<div class="info" v-if="ShowAvailability && Listing.availability && Listing.availability.availabilityString">
			<!-- <span :class="'availability ' + (Listing.availability.available ? 'available' : 'unavailable')">{{ Listing.availability.available ? 'Available' : 'Unavailable'}} </span> -->
			<span class="availability available">{{ Listing.availability.availabilityString }}</span>
		</div>
		<!--
		<div class="actions" v-if="!Lite">
			<b-button size="sm" variant="outline-success"><b-icon-email/> Contact the Owner</b-button> <b-button size="sm" variant="outline-secondary"><b-icon-stopwatch /> Hold</b-button>
		</div> -->
	</div>
	
</template>

<script>
export default {
	props: ['Listing', 'Lite', 'ShowAvailability', 'Member', 'Favourite'],
	data() {
		return{
			index:0,
			searchQuery: ""
		}
	},
	methods: {
		saveFavourite: function(){
			var fave = { listingId:this.listing.id };
			this.$http.post(this.$config.ApiUri + "/Customers/00000000-0000-0000-0000-000000000000/SavedListings", fave)
				.then(() => {
					this.Favourite = true;
				}
			)
		},
		unSaveFavourite: function(){
			this.$http.delete(this.$config.ApiUri + "/Customers/00000000-0000-0000-0000-000000000000/SavedListings/" + this.listing.id)
				.then(() => {
					this.Favourite = false;
				}
			)
		},
		onLoad() {
		}
	},
	mounted: function() { this.onLoad(); }
}

</script>

<style scoped>
	.listingResult
	{
		padding:0px;
		m--ax-width: 500px;
		margin-right: auto;
		margin-left: auto;
		padding-bottom: 25px;
		margin-top: 10px;
		float: left;
		margin-right: 10px;
	}

	
		.listingResult{
			float:unset;
			margin-right: auto;
		}
	

	.rounded-top 
	{
		border-top-left-radius: 0.4rem !important;
		border-top-right-radius: 0.4rem !important;
	}

	@media all and (max-width: 516px)
	{
		.rounded-top 
		{
			border-top-left-radius: 0rem !important;
			border-top-right-radius: 0rem !important;
		}
	}

	.listingResult .listing-title
	{
		font-family: GrifintolL, 'Gloock', serif !important;
		padding-left: 15px;
		font-size:22pt;
		line-height: 1;
		t--ext-transform: uppercase !important;
		margin-bottom: -41px;
		padding-top: 4px;
		padding-bottom: 4px;
		position: relative;
		background-color: #ffffff88;
		z-index: 1000;

	}

	.listingResult .listing-subtitle
	{
		font-family: GrifintolL, 'Gloock', serif !important;
		padding-left: 15px;
		font-size:19pt;
		line-height: 1;
		font-style: italic;
	}
	

	.listingResult .property-photo
	{
		width: 100%;
		margin-top: 4px;
	}
	.listingResult .priceInfo
	{
		padding: 10px;
		padding-left: 15px;
		background-color: #b6b1a6;
		color: white;
		border:0px;
	}
	.alt
	{
		background-color: #b6b1a6 !important;
	}
	.listingResult .info
	{
		border:0px;
		padding: 5px;
		padding-left: 15px;
		padding-right: 15px;
		color: black;		
	}
	.info-smaller
	{
		b--ackground-color: #f8f2e5;
		background-color: #ffffff;
		color: black;	
		p--adding-left: 10px;
		padding-left: 15px;
		padding-top: 3px;
		padding-bottom: 3px;
	}
	.listingResult .info .address{
		font-weight: bold;
	}
	.listingResult .info .favourite{
		margin-top: 5px;
		text-align: right;
	}
	.listingResult .info .feature{
		margin-right: 20px;
	}
	.listingResult .actions
	{
		border:0px;
		background-color: #b6b1a6;
		padding: 10px;
		padding-left: 15px;
		border-bottom-left-radius: 0.2rem !important;
		border-bottom-right-radius: 0.2rem !important;
		box-shadow: 0px 1px 0px 0px rgba(172, 172, 172, 0.75);
	}
	.bottom-curved
	{
		border-bottom-left-radius: 0.4rem !important;
		border-bottom-right-radius: 0.4rem !important;
	}

	@media all and (max-width: 516px)
	{
		.bottom-curved
		{
			border-bottom-left-radius: 0rem !important;
			border-bottom-right-radius: 0rem !important;
		}
	}

	.btn
	{
		background-color: #ffffff !important;
	}
	.availability
	{
		border-radius: 3px;
		color: #000000;
		padding: 3px;
		font-size: 14px;
	}
	.available
	{
		background-color: rgb(151 195 151);
		
	}
	.unavailable
	{
		background-color: rgb(213 155 155);
	}

</style>