<template>
	<div id="search">
		<b-input-group>
			<b-form-input v-model="search.query" placeholder="search"></b-form-input>
			<b-input-group-append>
				<b-button v-if="isLite" variant="outline-secondary" @click="doSearch">
					<b-icon icon="search" />
				</b-button>
				<b-button v-if="isLite" variant="outline-secondary">
					<b-icon icon="arrow-down" @click="isLite=false" />
				</b-button>
				<b-button v-if="!isLite" variant="outline-secondary">
					<b-icon icon="x-circle" @click="resetSearch(); isLite=true" />
				</b-button>
			</b-input-group-append>
		</b-input-group>
		<div id="more" class="margin-top" v-if="!isLite">
			<b-row>
				<!-- <b-col>
					<b-form-group id="input-group-1" label="Location:" label-for="location" description="">
						<b-form-input id="location" v-model="search.location" type="text"></b-form-input>
					</b-form-group>
				</b-col> -->
				<b-col>
					<b-form-group id="input-group-1" label="Min Bedrooms:" label-for="bedrooms" description="">
						<b-form-input id="bedrooms" v-model="search.bedrooms" type="number"></b-form-input>
					</b-form-group>
				</b-col>	
			</b-row>
			<b-row>
				<b-col>
					<b-form-group id="input-group-1" label="Available From:" label-for="checkin" description="">
						<b-form-input id="checkin" v-model="search.checkIn" type="date"></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>
			<b-row>
				<!-- <b-col>
					<b-form-group id="input-group-1" label="Price From:" label-for="priceFrom" description="">
						<b-form-input id="priceFrom" v-model="search.priceFrom" type="number"></b-form-input>
					</b-form-group>
				</b-col> -->
				<b-col>
					<b-form-group id="input-group-1" label="Price To:" label-for="priceTo" description="">
						<b-form-input id="priceTo" v-model="search.priceTo" type="number"></b-form-input>
					</b-form-group>
				</b-col>
				<b-col>
					<b-form-group id="input-group-1" label="Per:" label-for="priceType">
						<b-form-radio v-model="search.priceType" name="priceType" value="0">&nbsp;Week</b-form-radio>
						<b-form-radio v-model="search.priceType" name="priceType" value="1">&nbsp;Month</b-form-radio>
					</b-form-group>
				</b-col>
			</b-row>
			<b-row>
			</b-row>
			<b-row>
				<b-col>
					<b-form-group id="input-group-1" label="Property Type:" label-for="propertyType">
						<b-form-radio-group>
							<b-form-radio v-model="search.propertyType" name="propertyType" value="99">&nbsp;Any</b-form-radio>
							<b-form-radio v-model="search.propertyType" name="propertyType" value="0">&nbsp;Room</b-form-radio>
							<b-form-radio v-model="search.propertyType" name="propertyType" value="1">&nbsp;Studio</b-form-radio>
							<b-form-radio v-model="search.propertyType" name="propertyType" value="2">&nbsp;Apartment</b-form-radio>
							<b-form-radio v-model="search.propertyType" name="propertyType" value="3">&nbsp;Duplex</b-form-radio>
							<b-form-radio v-model="search.propertyType" name="propertyType" value="4">&nbsp;House</b-form-radio>
						</b-form-radio-group>
					</b-form-group>
				</b-col>
				<b-col>
					<b-form-group id="input-group-1" label="Rental type:" label-for="homeSwap" description="">
						<b-form-checkbox id="rental" v-model="search.rental">Rental</b-form-checkbox>
						<b-form-checkbox id="homeSwap" v-model="search.homeSwap">House swap</b-form-checkbox>
					</b-form-group>
				</b-col>
			</b-row>
			<div class="margined centered">
				<b-button @click="doSearch" variant="outline-primary" icon="search">Search</b-button>
			</div>
		</div>
		<div class="margin-top centered" v-if="results && results.length == 0">Sorry, no properties were found that match your search criteria.  Please widen your search and try again!</div>
		<div class="margin-top mb-3" v-if="results && results.length > 0">{{ results.length }} Listing(s) found</div>
		<div ref="results"></div>
	</div>
</template>

<script>
export default {
	props: {
		Lite: Boolean
	},
	data() {
		return{
			search: null,
			results: null,
			isLite: this.Lite
		}
	},
	methods: {
		resetSearch: function() {
			this.search = {
				query: "",
				priceType: 1,
				propertyType: 99,
				checkIn: null
			}
		},
		onSearchResults() {
			this.$emit('onSearchResults', this.results);
			if (this.results && this.results.length > 0 && !this.isLite) {
				this.$refs.results.scrollIntoView({ behavior: 'smooth' });
			}
		},
		doSearch: function(){
			this.search.priceType = parseInt(this.search.priceType);
			this.search.propertyType = parseInt(this.search.propertyType);
			if (this.search.bedrooms == "") { this.search.bedrooms = null; }
			if (this.search.priceFrom == "") { this.search.priceFrom = null; }
			if (this.search.priceTo == "") { this.search.priceTo = null; }
			if (this.search.checkIn == "") { this.search.checkIn = null; }
			if (this.search.propertyType == 99) { this.search.propertyType = null; }
			this.$http.post(this.$config.ApiUri + "/Listings/SearchRequests", this.search)
				.then((result) => {
					this.results = result.data;
					this.onSearchResults();
				}
			)
		},
	},
	mounted: function()
	{
		this.resetSearch();
	}
}
</script>

<style scoped>

#search
{
	padding-top: 15px; 
	padding-right: 20px;
	padding-left: 20px
}
	
</style>