<template>
	<div>
		<a :href="'/#/collections/' + Collection.id">
			<div v-if="Collection" :class="'centered margin-top color-block-content' + (!Details ? ' tall' : '')" 
				:style="'background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(\'' + Collection.image + '\');'">
				<h1>The {{Collection.name}} Collection</h1>
				<p>{{ Collection.description }}</p>
			</div>
		</a>
		<div class="map" v-if="Details && Collection.lat != null">
			<GmapMap :options="{
				zoomControl:true,  
				mapTypeControl: false,
				scaleControl: true,
				streetViewControl: true,
				rotateControl: false,
				fullscreenControl: true,
				disableDefaultUi: false
			}" 
				:center="{ lat:parseFloat(Collection.lat), lng:parseFloat(Collection.lng) }" 
				:zoom="Collection.mapZoom"
				style="width: 100%; height: 350px" 
				>
				<GmapMarker v-for="l in Collection.listings" :key="l.id" 
					:position="{ lat:parseFloat(l.property.address.lat), lng:parseFloat(l.property.address.lng) }"
					:draggable=false
					@click="propTapped"
				/>
				<GmapMarker v-for="p in places" :key="p.id" 
					:position="{ lat:parseFloat(p.lat), lng:parseFloat(p.lng) }"
					:draggable=false
					:icon="{ url: require('../assets/period.png')}"
					@click="placeTapped"
					:tag="p.id"
				/>
			</GmapMap>
		</div>

		<div class="place" v-if="selectedPlace">
			<b-row>
				<b-col v-if="selectedPlace.imageUri" cols="5"><b-img class="placeImage" :src="selectedPlace.imageUri"></b-img></b-col>
				<b-col>
					<div class="placeName">
						{{ selectedPlace.name }}
					</div>
					<div class="placeDesc">
						{{ selectedPlace.area }}<br/>
						{{ selectedPlace.bestFor }}
					</div>
					<div v-if="selectedPlace.url" class="placeLink"><a v-b-modal="'linkModal'" @click="linkTitle=selectedPlace.name; linkUrl=selectedPlace.url"><b-icon icon="globe-2" /></a></div>
				</b-col>
			</b-row>
		</div>

		<div class="place" v-if="selectedProperty">
			<b-row>
				<b-col cols="5"><b-img class="placeImage" :src="selectedProperty.property.photos[0].uri"></b-img></b-col>
				<b-col>
					<div class="placeName">
						{{ selectedProperty.property.address.oneLinerV2 }}
					</div>
					<div class="placeDesc">
						{{ selectedProperty.property.propertyTypeString }}<br/>
						{{ (selectedProperty.forRent && selectedProperty.forSwap ? 'Rental & Swaps' : (selectedProperty.forRent ? 'Rental' : 'Swaps')) }}<br/>
						{{ selectedProperty.property.bedrooms }} bedroom(s)
					</div>
					<div class="placeLink"><a :href="'/#/listings/' + selectedProperty.id"><b-icon icon="eye-fill" /></a></div>
				</b-col>
			</b-row>
		</div>

		<div v-if="Details">
			<a :href="'/#/listings/' + l.id" v-for="l in Collection.listings" :key="l.id">
				<div :ref="'listing-' + l.id"></div>
				<ListingResult Lite="true" :Listing="l" :ShowAvailability=true Member="member"/>
			</a>
		</div>

		<WebPopup :Title="linkTitle" :LinkURL="linkUrl" />
		
	<!-- <AreaGuide :PostCode="(Collection.listings[0].property.address.postCode != '' ? Collection.listings[0].property.address.postCode : Collection.listings[0].property.address.town)" /> -->
	</div>
</template>
<script>

import ListingResult from './ListingResult.vue'
import WebPopup from './WebPopup.vue'
//import AreaGuide from './AreaGuide.vue'

export default {
  name: 'Collection',
  props: ['Collection', 'Details'],
  components: {
	ListingResult,
	WebPopup
	//AreaGuide,
  },
  data() {
	return{
		selectedPlace: null,
		selectedProperty: null,
		areaGuides: {},
		places: [],
		linkTitle: null,
		linkUrl:null
	}
  },
  methods: {
	hideModal: function() {
		this.$bvModal.hide("modalMessage");
	},
	markerDragged: function(location) {
		this.Collection.lat = location.latLng.lat();
		this.Collection.lng = location.latLng.lng();
	},
	mapZoomed: function(z) {
		this.Collection.mapZoom = z;
	},
	placeTapped: function(m) {
		var place = this.findPlaceByLatLng(m.latLng.lat(), m.latLng.lng());
		if (place) {
			this.selectedProperty = null;
			this.selectedPlace = place;
		}
	},
	propTapped: function(m) {
		var prop = this.findPropByLatLng(m.latLng.lat(), m.latLng.lng());
		if (prop) {
			this.selectedPlace = null;
			this.selectedProperty = prop;
		}
	},
	findPlaceByLatLng: function(lat, lng) {
		//alert(lat);
		for (var x = 0; x < this.places.length; x++) {
			//alert (this.places[x].lat);
			if (this.places[x].lat == lat && this.places[x].lng == lng) {
				return this.places[x];
			}
		}
		return null;
	},
	findPropByLatLng: function(lat, lng) {
		//alert(lat);
		for (var x = 0; x < this.Collection.listings.length; x++) {
			//alert (this.places[x].lat);
			if (this.Collection.listings[x].property.address.lat == lat && this.Collection.listings[x].property.address.lng == lng) {
				return this.Collection.listings[x];
			}
		}
		return null;
	},
	getCollectionAreaGuides: function(){
		if (this.Collection.listings) {
			for (var x = 0; x < this.Collection.listings.length; x++)
			{
				var listing = this.Collection.listings[x];
				var area = listing.property.address.postCode != '' ? listing.property.address.postCode : listing.property.address.town
				this.$http.get(this.$config.ApiUri + "/AreaGuides/" + area)
				.then((result) => {
					var guide = result.data;
					if (guide && this.areaGuides[guide.id] == null) {
						//alert("adding pois from " + guide.name);
						for (var x = 0; x < guide.places.length; x++) {
							guide.places[x].area = guide.name;
						}
						this.areaGuides[guide.id] = guide;
						this.places = this.places.concat(guide.places);
						//alert("We have " + this.places.length + " places");
					}
				});
			}
		}
	},
	onLoad() {
		this.getCollectionAreaGuides();
	}
  },
  mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

h1 
{
	color: white;
}

.btn-link
{
	color: #000000 !important;
}

.place {
	background-color: #ffffff;
	color: black;
	padding: 10px;
	font-size: 10pt;
}

.placeImage{
	width: 100%;
	border-radius: 3px;
}

.placeName {
	
	font-weight: bold;
}

.placeLink {
	font-size: 14pt;
}

.color-block-content
{
	color: white;
	background-color: rgb(142, 164, 135);
	margin-top: -20px;
	padding-bottom: 20px;
	margin-bottom: -10px;
	background-position: center;
	background-size: cover;
	background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
	height: 200px;
	padding-top:30px;
}

.tall {
	height: 500px;
	padding-top:160px;
}


@media all and (max-width: 700px)
{
	.tall {
		height: 300px;
		padding-top:80px;
	}
}

.color-block-content h1{
	margin: 10px;
	padding-top: 20px !important;
}


</style>