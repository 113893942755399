<template>
	<div id="fullWidthContent" :style="'min-height:' + this.$parent.heightAfterHeaderFooter + 'px'">

		<h1 class="title">Three Degrees</h1>

		<div class="margined">
			<h2 class="centered">
				Reset your Password <div v-if="codeAutoSet">{{ activateRequest.email}} </div>
			</h2>

			<p v-if="!done && !enterCode && !emailSet">
				Please enter your email address below to reset your password.
			</p>
			<p v-if="!done && emailSet">
				Please check your email for a reset link.  You may need to copy and paste the link into your browser.
			</p>

			<h2 v-if="error">
				Sorry, something went wrong!
			</h2>
			
			<b-form v-if="!done">
				<div v-if="!enterCode && !emailSet">
					<b-form-group id="input-group-1" label="Email Address:" label-for="txtEmail">
						<b-form-input id="txtEmail" v-model="activateRequest.email" type="email" placeholder="Enter email" required></b-form-input>
					</b-form-group>
					<div class="margined centered more-margin-top more-margin-bottom">
						<b-button @click="activate" :disabled="activateSubmitted" variant="outline-primary">Reset</b-button>
					</div>
				</div>
				<div v-if="enterCode">
					<div v-if="!codeAutoSet">
						<p class="bit-more-margin-top">Please enter the code that you were just emailed.  If you did not receive an email, please check your spam folders.</p>
						<b-form-group id="input-group-1" label="Code:" label-for="txtCode">
							<b-form-input id="txtCode" v-model="activateRequest.code" type="number" placeholder="Enter code" required></b-form-input>
						</b-form-group>
					</div>
					<p class="bit-more-margin-top">Passwords must be >= 8 characters and contain a number, an uppercase letter and a symbol.</p>
					<b-form-group id="input-group-1" label="Password:" label-for="txtPassword1">
						<b-form-input id="txtPassword1" v-model="activateRequest.password1" type="password" placeholder="" required></b-form-input>
					</b-form-group>
					<b-form-group id="input-group-1" label="Password Again:" label-for="txtPassword2">
						<b-form-input id="txtPassword2" v-model="activateRequest.password2" type="password" placeholder="" required></b-form-input>
					</b-form-group>
					<div class="margined centered more-margin-top more-margin-bottom">
						<b-button @click="setPassword" :disabled="setPasswordSubmitted" variant="outline-primary">Set Password</b-button>
					</div>
				</div>
			</b-form>
			<div v-if="done">
				<p class="bit-more-margin-top">Thanks, your password has been set.</p>
				<div class="margined centered more-margin-top more-margin-bottom">
					<strong>Open the ThreeDegrees App To Login</strong>
				</div>
			</div>
		</div>

		<b-modal id="modalMessage" centered title="Reset Password" hide-header="true">
			<h2>Please fix the following:</h2>
			<p class="my-4" v-html="message"></p>
			<template #modal-footer>
				<!-- Emulate built in modal footer ok and cancel button actions -->
				<b-button size="" variant="primary" @click="hideModal">
					OK
				</b-button>
			</template>
		</b-modal>

	</div>
</template>

<script>

export default {
	name: "Activate",
	metaInfo() {
        return { 
            title: "Three Degrees - Password Reset",
        }
    },
	data() {
		return {
			activateRequest: 
				{
					"email": null,
					"password1": null,
					"password2": null,
					"code": null
				},
			emailSet: false,
			enterCode: false,
			codeAutoSet: false,
			error: false,
			message:"",
			done: false,
			activateSubmitted: false,
			setPasswordSubmitted: false
		};
	},	
	methods: {
		hideModal: function() {
			this.$bvModal.hide("modalMessage");
		},
		login: function() {
			this.$router.push("/login");
		},
		validateInput: function() {
			this.message = "";
			if (this.activateRequest.email == "" || this.activateRequest.email == null) { this.message = "<br/>Please enter your email address"; }
			if (this.message != "") 
			{
				this.$bvModal.show("modalMessage"); 
				return false;
			}
			return true;
		},
		validateInput2: function() {
			this.message = "";
			if (this.activateRequest.password1 == "" || this.activateRequest.password1 == null) { this.message += "<br/>Please enter a password"; }
			if (this.activateRequest.password1 != this.activateRequest.password2) { this.message += "<br/>Passwords do not match"; }
			if (this.activateRequest.code == "" || this.activateRequest.code == null) { this.message += "<br/>Please enter your code"; }
			if (this.message != "") 
			{
				this.$bvModal.show("modalMessage"); 
				return false;
			}
			return true;
		},
		activate: function() {
			this.error = false;
			this.message = "";
			if (!this.validateInput()) { return; }
			this.message = null;
			this.activateSubmitted = true;
			this.$http.post(this.$config.ApiUri + "/Password/ResetRequests?email=" + this.activateRequest.email + "&isActivation=false", null)
				.then(() => {
					//this.enterCode = true;
					this.emailSet = true;
					return;
				}
			)
			.catch(() => { 
				this.message = "That didn't work.  Did you copy the link from your email?";
				this.$bvModal.show("modalMessage");
			})
		},
		setPassword: function() {
			this.error = false;
			if (!this.validateInput2()) { return; }
			this.message = null;
			this.setPasswordSubmitted = true;
			this.$http.post(this.$config.ApiUri + "/Password/VerifyResetCodeRequests?e=" + this.activateRequest.email + "&c=" + this.activateRequest.code, null)
				.then((result) => {
					this.$http.post(this.$config.ApiUri + "/Password/SetRequests?r=" + result.data + "&e=" + this.activateRequest.email + "&p=" + this.activateRequest.password1, null)
						.then(() => {
							this.done = true;
						}
					).catch(() => {
						this.message = "That didn't work.  Is your password strong enough?";
						this.setPasswordSubmitted = false;
						this.$bvModal.show("modalMessage");
					})
				}
			).catch(() => { 
				this.message = "That didn't work.  Did you copy the link from your email?";
				this.setPasswordSubmitted = false;
				this.$bvModal.show("modalMessage");
			})
		},
		onLoad: function () {
			this.activateRequest.code = this.$route.query.code;
			this.activateRequest.email = this.$route.query.email;
			if (this.activateRequest.email){
				this.enterCode = true;
				this.codeAutoSet = true;
			}
		},
	},
	mounted: function () {
		this.onLoad();
	},
};
</script>

<style scoped>


.social
{
	height: 22px;
	position: absolute;
	right: 0px;
	width: 22px;
	opacity: 0.5;
	margin:14px;
}

.social img
{
	height: 100%;
}

</style>