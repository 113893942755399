<template>
	<div id="fullWidthContent" class="filledBg">

		<div class="centered" style="padding-top:20px" v-if="self">
			<a :href="'/#/selfs/' + self.id"><b-avatar :src="self.profilePicture"
					:text="self.person.firstName[0] + self.person.lastName[0]" size="5rem"></b-avatar></a><br />
			<b-link href="/#/profile"><small>edit profile</small></b-link>
		</div>

		<div class="margined centered" v-if="self">
			<h2>Welcome {{ self.person.firstName }}.</h2>
		</div>

		<div v-if="contacts" class="margined centered more-margin-top">
			<b-button v-b-toggle.collapse-my-network variant="outline-primary"><b-icon icon="link"></b-icon>&nbsp; My
				Network ({{ contacts.length }}) <b-icon icon="arrow-down-short" /></b-button>
		</div>

		<b-collapse v-if="contacts" id="collapse-my-network">
			<div v-if="contacts.length == 0" class="margined centered">
				<p class="margin-side">You do not have any contacts who use Three Degrees. Why not spread the news!?
					(Note, you must allow Three Degrees access to your contacts when prompted!)</p>
			</div>
			<div class="margined centered">
				<a :href="'/#/members/' + c.id" v-for="c in contacts" :key="c.id">
					<b-avatar class="mt-1" :src="c.profilePicture" :text="c.person.firstName[0] + c.person.lastName[0]"
						size="3rem"></b-avatar>&nbsp;
				</a>
			</div>
			<div style="clear:both" />
		</b-collapse>

		<div v-if="listings" class="margined centered more-margin-top">
			<b-button v-b-toggle.collapse-my-listings variant="outline-primary"><b-icon
					icon="house-door"></b-icon>&nbsp; My Spaces ({{ listings.length }}) <b-icon
					icon="arrow-down-short" /></b-button>
		</div>

		<b-collapse v-if="listings" id="collapse-my-listings">
			<div v-if="listings.length == 0" class="margined centered">
				<p class="margin-side">You do not have any listings yet, begin by creating one!</p>
			</div>
			<div class="listings-wrapper">
				<a :href="'/#/listings/' + l.id" v-for="l in listings" :key="l.id">
					<ListingResult :Listing="l" Lite="true" :ShowAvailability=true />
				</a>
			</div>
			<div style="clear:both" />
		</b-collapse>

		<div v-if="savedListings" class="margined centered more-margin-top">
			<b-button v-b-toggle.collapse-my-properties variant="outline-primary"><b-icon icon="heart"></b-icon>&nbsp;
				My Favourites ({{ savedListings.length }}) <b-icon icon="arrow-down-short" /></b-button>
		</div>

		<b-collapse v-if="savedListings" id="collapse-my-properties">
			<div v-if="!savedListings || savedListings.length == 0" class="margined centered">
				<p>You do not have any saved listings yet, begin by searching and saving a few!</p>
			</div>
			<div class="listings-wrapper">
				<a :href="'/#/listings/' + l.id" v-for="l in savedListings" :key="l.id">
					<ListingResult :Listing="l" Lite="true" :ShowAvailability=true />
				</a>
			</div>
			<div style="clear:both" />
		</b-collapse>

		<div v-if="admin">

			<div v-if="approvalQueue" class="margined centered more-margin-top">
				<b-button v-b-toggle.collapse-approvals variant="outline-primary"><b-icon
						icon="house-door"></b-icon>&nbsp; Approval Queue ({{ approvalQueue.length }}) <b-icon
						icon="arrow-down-short" /></b-button>
			</div>

			<b-collapse v-if="approvalQueue" id="collapse-approvals">
				<div v-if="approvalQueue.length == 0" class="margined centered">
					<p class="margin-side">No approvals pending.</p>
				</div>
				<div class="listings-wrapper">
					<a :href="'/#/listings/' + l.id" v-for="l in approvalQueue" :key="l.id">
						<ListingResult :Listing="l" Lite="true" :ShowAvailability=true />
					</a>
				</div>
				<div style="clear:both" />
			</b-collapse>

			<div v-if="approved" class="margined centered more-margin-top">
				<b-button v-b-toggle.collapse-approved variant="outline-primary"><b-icon
						icon="house-door"></b-icon>&nbsp; Approved Properties ({{ approved.length }}) <b-icon
						icon="arrow-down-short" /></b-button>
			</div>

			<b-collapse v-if="approved" id="collapse-approved">
				<div v-if="approved.length == 0" class="margined centered">
					<p class="margin-side">No approved properties.</p>
				</div>
				<div class="listings-wrapper">
					<a :href="'/#/listings/' + l.id" v-for="l in approved" :key="l.id">
						<ListingResult :Listing="l" Lite="true" :ShowAvailability=true />
					</a>
				</div>
				<div style="clear:both" />
			</b-collapse>

			<div class="margined centered more-margin-top">
				<b-button href="#/AreaGuidesAdmin" variant="outline-primary"><b-icon icon="map"></b-icon>&nbsp; Area
					Guide Admin</b-button>
			</div>

			<div class="margined centered more-margin-top">
				<b-button href="#/CollectionsAdmin" variant="outline-primary"><b-icon icon="map"></b-icon>&nbsp;
					Collections Admin</b-button>
			</div>

		</div>

		<div class="margined centered more-margin-top more-margin-bottom">
			<b-button @click="addListing" variant="outline-primary"><b-icon icon="plus"></b-icon>&nbsp; Create a
				Listing</b-button>
		</div>

		<b-modal id="modalMessage" centered title="Error" :hide-header="true">
			<h2>Please fix the following:</h2>
			<p class="my-4" v-html="message"></p>
			<template #modal-footer>
				<!-- Emulate built in modal footer ok and cancel button actions -->
				<b-button size="" variant="primary" @click="hideModal">
					OK
				</b-button>
			</template>
		</b-modal>

		<b-modal id="modalEnquire" centered title="Reply" :hide-header="true">
			<div v-if="enquiry">
				<h3 class="mb-3">You are messaging {{ enquiry.from.person.firstName }}</h3>
				<b-form-input placeholder="Subject" v-model="enquiry.subject" readonly></b-form-input><br />
				<b-form-textarea rows="6" placeholder="Your Message" v-model="enquiry.body"></b-form-textarea>
			</div>
			<template #modal-footer>
				<!-- Emulate built in modal footer ok and cancel button actions -->
				<b-button size="" variant="outline-secondary" @click="hideEnquiryForm">
					Cancel
				</b-button>

				<b-button size="" variant="outline-secondary" @click="sendEnquiry">
					OK
				</b-button>
			</template>
		</b-modal>

	</div>
</template>
<script>
import ListingResult from './ListingResult.vue'
import Services from '../mixins/Services'

export default {
	name: 'MemberHome',
	metaInfo() {
		return {
			title: "Three Degrees - Member Home",
		}
	},
	mixins: [Services],
	components: {
		ListingResult
	},
	data() {
		return {
			message: "",
			contacts: null,
			listings: null,
			approvalQueue: null,
			savedListings: null,
			searchQuery: "",
			enquiry: null,
			interval: null,
			admin: false,
			approved: null
		}
	},
	methods: {
		listingTitle: function (listingId) {
			for (var x = 0; x < this.listings.length; x++) {
				if (this.listings[x].id == listingId) {
					return this.listings[x].property.address.oneLinerV2
				}
			}
			return "???";
		},
		getMember: function () {
			this._getSelf(null, () => { this.getCommonContacts(); });
			if (this.$route && this.$route.meta && this.$route.meta.admin) {
				this.admin = true;
			}
		},
		getCommonContacts: function () {
			this.$http.get(this.$config.ApiUri + "/Customers/00000000-0000-0000-0000-000000000000/Contacts")
				.then((result) => {
					this.contacts = result.data;
				}
				)
		},
		getMemberListings: function () {
			this.$http.get(this.$config.ApiUri + "/Customers/00000000-0000-0000-0000-000000000000/Listings")
				.then((result) => {
					this.listings = result.data;
					//this.getMessages();
				}
				)
		},
		getApprovalQueue: function () {
			if (this.admin) {
				this.$http.get(this.$config.ApiUri + "/Listings?approved=false")
					.then((result) => {
						this.approvalQueue = result.data;
					}
					)
			}
		},
		getApproved: function () {
			if (this.admin) {
				this.$http.get(this.$config.ApiUri + "/Listings")
					.then((result) => {
						this.approved = result.data;
					}
					)
			}
		},
		getMemberSavedListings: function () {
			this.$http.get(this.$config.ApiUri + "/Customers/00000000-0000-0000-0000-000000000000/SavedListings")
				.then((result) => {
					this.savedListings = result.data;
				}
				)
		},
		getMessages: function () {
			this.$http.get(this.$config.ApiUri + "/Customers/00000000-0000-0000-0000-000000000000/Enquiries")
				.then((result) => {
					this.messages = result.data;
				}
				)
		},
		addListing: function () {
			this.$router.push("/createlisting");
		},
		onLoad() {
			this.getMember();
			this.getMemberListings();
			this.getMemberSavedListings();
			this.getApprovalQueue();
			this.getApproved();
			if (window.nativeApp) { window.nativeApp.postMessage("contacts"); }
		},
		onUnLoad() {
			//clearInterval(this.interval);
		}
	},
	mounted: function () { this.onLoad(); },
	destroyed: function () { this.onUnLoad(); }
}
</script>

<style scoped>
#fullWidthContent {
	min-height: 90vh;
}

.inbound-message {
	color: rgb(12, 80, 35);
}

.outbound-message {
	color: #444444;
}

.badge-secondary {
	color: #fff;
	background-color: #b6b1a6;
}

.message {
	margin-left: 30px;
	margin-right: 30px;
	margin-top: 10px;
	padding-bottom: 10px;
	padding-right: 10px;
	border-bottom: 1px solid white;
}
</style>