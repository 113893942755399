<template>

	<div id="fullWidthContent" :style="'min-height:' + this.$parent.heightAfterHeaderFooter + 'px'">

		<b-button class="backNav" @click="$router.back()" size="xs" variant="link"><b-icon-caret-left-fill /></b-button>
		
		<div class="margined">
			<b-row class="mt-3">
				<!-- <b-col>
					<b-img fluid :src="require('@/assets/landing.png')" />
				</b-col> -->
				<b-col>
					<div>
						<h1 class="centered" v-if="!registered">
							Welcome to the future of living
						</h1>
		
						<p class="bit-more-margin-top" v-if="!registered">Three Degrees connects you with friends and friends-of-friends to help you find exceptional homes. We think everyone should be able to rent from a network they can trust, without sacrificing on style. Which is why we vet everyone who applies to our community, so you don't have to.  It's renting, just done better.</p>

						<p class="bit-more-margin-top" v-if="!registered">Access is by application, invitation and referral only. So, whether you are looking for a home, have a home or room to rent, or just curious to take a look in to our world, apply today. Renters & owners welcome. </p>
						
						<h1 class="centered" v-if="registered && !autoApproved">Thank you for your application!</h1>
						<p v-if="registered && !autoApproved">Our team are reviewing your details and will be in touch soon. Keep an eye on your inbox for an email to activate your membership. 

In the mean time, stay connected and follow us <a target="_blank" href="https://www.instagram.com/three_degrees_">@three_degrees_</a> for more news and updates!</p>
						
						<h1 class="centered" v-if="autoApproved">Your Application is Approved!</h1>
						<p v-if="autoApproved">Thanks, your application has been approved!  Please check your email for an activation link.</p>
						<p v-if="autoApproved">When you have activated, you can Login.</p>
						<div class="centered mt-5" v-if="autoApproved"><b-button href="/#/login" class="no-width-set" variant="outline-primary">Login</b-button></div>
						
						<b-form v-if="regRequest && !registered">
							<p>* All fields are required</p>
		
							<b-row>
								<b-col lg="6" md="6" sm="12">
									<b-form-group id="input-group-1" label="First Name:" label-for="txtFirstName" description="">
										<b-form-input id="txtFirstName" v-model="regRequest.person.firstName" type="text" placeholder="" required></b-form-input>
									</b-form-group>
								</b-col>
								<b-col lg="6" md="6" sm="12">
									<b-form-group id="input-group-1" label="Last Name:" label-for="txtLastName" description="">
										<b-form-input id="txtLastName" v-model="regRequest.person.lastName" type="text" required></b-form-input>
									</b-form-group>
								</b-col>
							</b-row>

							<b-row>
								<b-col lg="6" md="6" sm="12">
									<b-form-group id="input-group-1" label="Mobile Number:" label-for="txtMobile">
										<b-form-input id="txtMobile" v-model="regRequest.person.mobilePhone" type="phone" placeholder="" required></b-form-input>
									</b-form-group>
								</b-col>
								<b-col lg="6" md="6" sm="12">
									<b-form-group id="input-group-1" label="Referral Code (optional):" label-for="txtCode" description="">
										<b-form-input id="txtCode" v-model="regRequest.referralCode" type="text" required></b-form-input>
									</b-form-group>
								</b-col>
							</b-row>

							<b-row>
								<b-col lg="12" md="12" sm="12">
									<b-form-group id="input-group-1" label="I Live In:" label-for="txtCity">
										<b-form-input id="txtCity" v-model="regRequest.person.city" type="text" placeholder="City" required></b-form-input>
									</b-form-group>
								</b-col>
							</b-row>
		
							<b-row>
								<b-col lg="6" md="6" sm="12">
									<b-form-group id="input-group-1" label="Email Address:" label-for="txtEmail">
										<b-form-input id="txtEmail" v-model="regRequest.person.email" type="email" placeholder="Enter email" required></b-form-input>
									</b-form-group>
								</b-col>
								<b-col lg="6" md="6" sm="12">
									<b-form-group id="input-group-1" label-for="cboIam">
										<b-form-radio v-model="regRequest.person.regReason" :aria-describedby="ariaDescribedby" name="some-radios" value="Owner">&nbsp;I have a home or room to rent</b-form-radio>
										<b-form-radio v-model="regRequest.person.regReason" :aria-describedby="ariaDescribedby" name="some-radios" value="Renter">&nbsp;I am looking for somewhere to rent</b-form-radio>
										<b-form-radio v-model="regRequest.person.regReason" :aria-describedby="ariaDescribedby" name="some-radios" value="Both">&nbsp;I am interested in both</b-form-radio>
										<b-form-radio v-model="regRequest.person.regReason" :aria-describedby="ariaDescribedby" name="some-radios" value="Looking">&nbsp;I am just looking</b-form-radio>
									</b-form-group>
								</b-col>
							</b-row>
		
							<b-row>
								<b-col lg="6" md="6" sm="12">
									<b-form-group id="input-group-1" label="Instagram:" label-for="txtInsta">
										<b-form-input id="txtInsta" v-model="regRequest.person.insta" type="text" placeholder="@"
											required></b-form-input>
									</b-form-group>
								</b-col>
								<b-col lg="6" md="6" sm="12">
									<b-form-group id="input-group-1" label="Website:" label-for="txtWeb">
										<b-form-input id="txtWeb" v-model="regRequest.person.website" type="text" placeholder="www"
											required></b-form-input>
									</b-form-group>
								</b-col>
							</b-row>

							<b-row>
								<b-col>
									<b-form-group id="input-group-1" label="Linked In:" label-for="txtLinkedIn">
										<b-form-input id="txtLinkedIn" v-model="regRequest.person.linkedIn" type="text" placeholder=""
											required></b-form-input>
									</b-form-group>
								</b-col>
							</b-row>

					
							<div class="margined centered more-margin-top more-margin-bottom">
								<b-button @click="register" :disabled="submitted" variant="outline-primary">{{regRequest.referralCode == referralCode ? 'Sign Up' : 'Submit Application' }}</b-button>
								<!-- <p class="mt-3">Already got an account?</p>
								<b-button href="/#/login" variant="outline-primary">Login</b-button> -->
							</div>
							
						</b-form>
					</div>
				</b-col>
			</b-row>
		</div>

		<b-modal id="modalMessage" centered title="Register" hide-header="true">
			<h2>Please fix the following:</h2>
			<p class="my-4" v-html="message"></p>
			<template #modal-footer>
				<!-- Emulate built in modal footer ok and cancel button actions -->
				<b-button size="" variant="primary" @click="hideModal">
					OK
				</b-button>
			</template>
		</b-modal>

	</div>

</template>

<script>

export default {
	name: "Register",
	metaInfo() {
        return { 
            title: "Three Degrees - Register",
        }
    },
	data() {
		return {
			regRequest: null,
			registered: false,
			customerId: null,
			message:"",
			options: [
				{ value: null, text: 'Please select an option' },
				{ value: 'owner', text: 'Renting out a property' },
				{ value: 'renter', text: 'Wishing to rent a property' },
			],
			referralCode: "MEMBER24",
			autoApproved: false,
			submitted: false
		};
	},	
	methods: {
		hideModal: function() {
			this.$bvModal.hide("modalMessage");
		},
		validateInput: function() {
			if (this.regRequest.person.firstName == "") { this.message += "<br/>Please enter your first name"; }
			if (this.regRequest.person.lastName == "") { this.message += "<br/>Please enter your last name"; }
			if (this.regRequest.person.email == "") { this.message += "<br/>Please enter your email address"; }
			if (this.regRequest.person.mobileNumber == "") { this.message += "<br/>Please enter your mobile number"; }
			if (this.regRequest.person.city == "") { this.message += "<br/>Please enter your city"; }
			if (this.regRequest.person.regReason == "") { this.message += "<br/>Please tell us why you are joining the waitlist"; }
			if (this.regRequest.person.insta == "") { this.message += "<br/>Please enter your instagram profile"; }
			if (this.message != "") 
			{
				this.$bvModal.show("modalMessage"); 
				return false;
			}
			return true;
		},
		register: function() {
			this.message = "";
			if (!this.validateInput()) { return; }
			this.submitted = true;
			this.$http.post(this.$config.ApiUri + "/Customers?verifyEmail=false", this.regRequest)
				.then((result) => {
					this.registered = result.data.success;
					if (!this.registered) {
						this.message = result.data.message;
						this.$bvModal.show("modalMessage"); 
					}
					else {
						if (this.regRequest.referralCode == this.referralCode) {
							this.$http.post(this.$config.ApiUri + "/Customers/" + result.data.customer.id + "/ApprovalRequests")
								.then(() => {
									this.autoApproved = true;
								}
							)
						}
					}
					this.customerId = result.data.id;
				}
			)
		},
		initReg: function()
		{
			this.regRequest = 
			{
				"person": {
					"title": "",
					"firstName": "",
					"lastName": "",
					"dob": null,
					"linkedIn": "",
					"insta": "",
					"website": "",
					"regReason": "",
					"gender": "u",
					"mobilePhone": "",
					"city": "",
					"email": "",
					"password": null,
					"nationality": "UK",
					"residency": "UK",
					"address": null
				}
			
			};
		},
		onLoad: function () {
			this.initReg();
		},
	},
	mounted: function () {
		this.onLoad();
	},
};
</script>

<style scoped>

#app
{
	background-color: #D7D1C4 !important;
}

.btn-link
{
	color: #000000 !important;
}

</style>