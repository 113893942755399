<template>
	
		<div id="narrow-content">
			<b-button class="backNav" @click="$router.back()" size="xs" variant="link"><b-icon-caret-left-fill /></b-button>
		
			<h1>Help &amp; Support</h1>

			<h2>We're Here For You</h2>

			<p>We are keen for you have the best possible experience using Three Degrees.  If you have any issues or suggestions please contact info@threedegrees.world</p>
			
			<p>We review all property listing as part of the service and will enhance your listing details to make sure they are as informative and appealing as possible.  We may from time to time reject photographs and ask you to retake them to ensure our quality standards are met.</p>
			<p>We are always on the look out for great places to add to our area guide, so please feel free to let us know about your fave places when you are setting up your listing!</p>
			<p><small>Three Degrees is not involved in the direct communication or actual face-to-face contact between members and we are not involved in the members’ agreements between themselves. As a result, we have no control over either the conduct of our members or the truth or accuracy of the information that members post on our site. You are solely responsible for your interactions with other members of our network. In agreeing to rent through our network, you accept that Three Degrees does not verify each listing and does not guarantee this information to be correct. Three Degrees assumes no liability regarding the accuracy, content, or availability of information accessed or linked to through our app, including information provided by members.</small></p>
		</div>
	
</template>

<script>

export default {
	name: "Support",
	metaInfo() {
        return { 
            title: "Three Degrees - Support",
        }
    },
	data() {
		return {
			
		};
	},	
	methods: {
		
		onLoad: function () {
			
		},
	},
	mounted: function () {
		this.onLoad();
	},
};
</script>

<style scoped>

#app
{
	background-color: #D7D1C4 !important;
}

#narrow-content{
	background-color: #ffffff !important;
}

.btn-link
{
	color: #000000 !important;
}

</style>