<template>
	
		<div id="narrow-content">
			<b-button class="backNav" @click="$router.back()" size="xs" variant="link"><b-icon-caret-left-fill /></b-button>
		
			<h1>Privacy Notice</h1>
	<p class="c23"><span class="c17">Last Updated October 2023</span></p>
	<p class="c0"><span class="c12">Thank you for choosing to be part of our community at Three Degrees
			(&quot;</span><span class="c13">Three Degrees</span><span class="c12">&quot;, &quot;</span><span
			class="c13">we</span><span class="c12">&quot;, &quot;</span><span class="c13">us</span><span
			class="c12">&quot;, &quot;</span><span class="c13">our</span><span class="c12">&quot;). We are committed to
			protecting your personal information and your right to privacy. We take your privacy very seriously. In this
			privacy notice, we seek to explain to you in the clearest way possible what information we collect, how we
			use it and what rights you have in relation to it. </span></p>
	<p class="c0 c7"><span class="c5"></span></p>
	<p class="c0"><span class="c12">When you use any of our services (the &quot;</span><span
			class="c13">Services</span><span class="c6">&rdquo;), we appreciate that you are trusting us with your
			personal information. This privacy notice applies to all information collected through our Services, as well
			as, any related services, sales, marketing or events.</span></p>
	<p class="c0 c7"><span class="c5"></span></p>
	<p class="c0 c7"><span class="c5"></span></p>
	<p class="c0"><span class="c1">Please read this privacy notice carefully as it will help you understand what we do
			with the information that we collect.</span></p>
	<p class="c0 c7"><span class="c1"></span></p>
	<p class="c0 c7"><span class="c1"></span></p>
	<ol class="c10 lst-kix_eiwkn8wu108-0 start" start="1">
		<li class="c0 c14 li-bullet-0"><span class="c1">WHAT INFORMATION DO WE COLLECT?</span></li>
	</ol>
	<p class="c0 c7 c16"><span class="c1"></span></p>
	<p class="c0"><span class="c1">Personal information you disclose to us</span></p>
	<p class="c0 c7"><span class="c5"></span></p>
	<p class="c0"><span class="c2">In Short:&nbsp; </span><span class="c19 c12">We collect personal information that you
			provide to us.</span></p>
	<p class="c0 c7"><span class="c5"></span></p>
	<p class="c0"><span class="c6">We collect personal information that you voluntarily provide to us when you express
			an interest in obtaining information about us or our products and Services when you contact us.</span></p>
	<p class="c0 c7"><span class="c5"></span></p>
	<p class="c0"><span class="c6">The personal information that we collect depends on the context of your interactions
			with us, the choices you make and the products and features you use. The personal information we collect may
			include the following:</span></p>
	<p class="c0 c7"><span class="c5"></span></p>
	<ul class="c10 lst-kix_g7o5oufoklnr-0 start">
		<li class="c14 c18 li-bullet-0"><span class="c13">Personal Information Provided by You</span><span class="c6">.
				We collect names and contact details of individuals that have expressed an interest in using the Three
				Degrees platform.</span></li>
	</ul>
	<p class="c0"><span class="c6">All personal information that you provide to us must be true, complete and accurate,
			and you must notify us of any changes to such personal information.</span></p>
	<p class="c0 c7"><span class="c6"></span></p>
	<p class="c0 c7"><span class="c5"></span></p>
	<ol class="c10 lst-kix_eiwkn8wu108-0" start="2">
		<li class="c0 c14 li-bullet-0"><span class="c1">HOW DO WE USE YOUR INFORMATION?</span></li>
	</ol>
	<p class="c0 c7 c16"><span class="c5"></span></p>
	<p class="c0"><span class="c2">In Short:&nbsp;</span><span class="c13">&nbsp;</span><span class="c12 c29">We will
			use your personal data to provide you with the Services you have selected. We process the information where
			it is necessary for our legitimate interests.</span></p>
	<p class="c0 c7"><span class="c22"></span></p>
	<p class="c0"><span class="c5">We use the personal information to contact you about your interest in using our
			Services. </span></p>
	<p class="c0"><span class="c5">We process your personal information for these purposes in reliance on our legitimate
			business interests, in order to enter into or perform a contract with you, with your consent, and/or for
			compliance with our legal and regulatory obligations. </span></p>
	<p class="c0 c7"><span class="c5"></span></p>
	<ul class="c10 lst-kix_pfpz62wihfpk-0 start">
		<li class="c14 c26 li-bullet-0"><span class="c13">Cookies &ndash; &nbsp;</span><span class="c12">We, or our
				third party providers, may also use cookies to collect information about you. This helps us to provide
				you with a good experience while you use our website.</span></li>
		<li class="c3 li-bullet-0"><span class="c13">Links to other websites &ndash; </span><span class="c12">Our
				website may, from time to time, contain links to and from the websites of our partner network &nbsp;and
				affiliates. If you follow a link to any of these websites, please note that these websites have their
				own privacy policies and that we do not accept any responsibility or liability for these policies.
				Please check these policies before you submit any personal information to these websites.</span></li>
	</ul>
	<ol class="c10 lst-kix_eiwkn8wu108-0" start="3">
		<li class="c0 c14 li-bullet-0"><span class="c1">WILL YOUR INFORMATION BE SHARED WITH ANYONE?</span></li>
	</ol>
	<p class="c0 c7 c16"><span class="c5"></span></p>
	<p class="c0"><span class="c2">In Short:</span><span class="c19 c12">&nbsp; We only share information with your
			consent, to comply with laws, to provide you with services, to protect your rights, or to fulfil our
			business obligations.</span></p>
	<p class="c0 c7"><span class="c5"></span></p>
	<p class="c0"><span class="c12">We may process or share your data that we hold based on the following legal
			basis:</span></p>
	<ul class="c10 lst-kix_kgtctya06swl-0 start">
		<li class="c9 li-bullet-0"><span class="c13">Consent:</span><span class="c6">&nbsp;We may process your data if
				you have given us specific consent to use your personal information to carry out our service. You may
				withdraw this at a later date if you change your mind. </span></li>
	</ul>
	<ul class="c10 lst-kix_qbwbkuanw95o-0 start">
		<li class="c0 c14 li-bullet-0"><span class="c13">Legitimate Interests:</span><span class="c6">&nbsp;We may
				process your data when it is reasonably necessary to achieve our legitimate business interests which
				include; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></li>
	</ul>
	<ul class="c10 lst-kix_qbwbkuanw95o-1 start">
		<li class="c0 c8 li-bullet-0"><span class="c6">Providing you with access to the Three Degrees app </span></li>
		<li class="c0 c8 li-bullet-0"><span class="c6">Tracking the use of the Three Degrees&nbsp;app and
				website&nbsp;and identify areas where we can improve performance or functionality (business and
				technical)</span></li>
		<li class="c0 c8 li-bullet-0"><span class="c6">Sending you support messages, updates, security alerts, and
				account notifications</span></li>
		<li class="c0 c8 li-bullet-0"><span class="c6">For business purposes. </span></li>
	</ul>
	<ul class="c10 lst-kix_tek7h6c1e430-0 start">
		<li class="c0 c14 li-bullet-0"><span class="c13">Performance of a Contract:</span><span class="c6">&nbsp;we will
				process your personal information to perform the services you have agreed to in our Terms and
				Conditions. </span></li>
	</ul>
	<ul class="c10 lst-kix_mqkwymich6dw-0 start">
		<li class="c9 li-bullet-0"><span class="c13">Legal Obligations:</span><span class="c6">&nbsp;we will process
				your personal information to prevent fraud, and ensure compliance with regulatory obligations &mdash; we
				process the information we collect given our legitimate interest to protect us from fraud, and to comply
				with our regulatory obligations.</span></li>
		<li class="c9 li-bullet-0"><span class="c13">Vital Interests:</span><span class="c6">&nbsp;We may disclose your
				information where we believe it is necessary to investigate, prevent, or take action regarding potential
				violations of our policies, suspected fraud, situations involving potential threats to the safety of any
				person and illegal activities, or as evidence in litigation in which we are involved.</span></li>
	</ul>
	<p class="c18 c7"><span class="c11"></span></p>
	<p class="c18 c7"><span class="c11"></span></p>
	<p class="c18 c7"><span class="c11"></span></p>
	<ol class="c10 lst-kix_eiwkn8wu108-0" start="4">
		<li class="c0 c14 li-bullet-0"><span class="c1">HOW LONG DO WE KEEP YOUR INFORMATION?</span></li>
	</ol>
	<p class="c0 c7 c16"><span class="c5"></span></p>
	<p class="c0"><span class="c2">In Short: </span><span class="c19 c12">&nbsp;We keep your information for as long as
			necessary to fulfil the purposes outlined in this privacy notice unless otherwise required by law.</span>
	</p>
	<p class="c0 c7"><span class="c5"></span></p>
	<p class="c0"><span class="c6">We will only keep your personal information for as long as it is necessary for the
			purposes set out in this privacy notice, unless a longer retention period is required or permitted by law
			(such as tax, accounting or other legal requirements). In line with our regulatory obligations, nothing
			precludes us from keeping your personal information for longer than 5 years.</span></p>
	<p class="c0 c7"><span class="c5"></span></p>
	<p class="c0"><span class="c6">When we have no ongoing legitimate business need to process your personal
			information, we will either delete or anonymise such information, or, if this is not possible (for example,
			because your personal information has been stored in backup archives), then we will securely store your
			personal information and isolate it from any further processing until deletion is possible.</span></p>
	<p class="c0 c7"><span class="c6"></span></p>
	<p class="c0 c7"><span class="c5"></span></p>
	<ol class="c10 lst-kix_eiwkn8wu108-0" start="5">
		<li class="c0 c14 li-bullet-0"><span class="c1">HOW DO WE KEEP YOUR INFORMATION SAFE?</span></li>
	</ol>
	<p class="c0 c7 c16"><span class="c5"></span></p>
	<p class="c0"><span class="c2">In Short: </span><span class="c19 c12">&nbsp;We aim to protect your personal
			information through a system of organisational and technical security measures.</span></p>
	<p class="c0 c7"><span class="c5"></span></p>
	<p class="c21"><span class="c5">All information you provide to us is stored on our secure servers. Any transmission
			of information to our partners (including information to facilitate payments) are encrypted using TLS
			technology, the current standard in secure communications over the Internet. Unfortunately, the transmission
			of information via the Internet is not completely secure. Although we will do our best to protect your
			personal data, we cannot guarantee the security of your data transmitted to our site; any transmission is at
			your own risk.</span></p>
	<ol class="c10 lst-kix_eiwkn8wu108-0" start="6">
		<li class="c0 c14 li-bullet-0"><span class="c1">WHAT ARE YOUR PRIVACY RIGHTS?</span></li>
	</ol>
	<p class="c0 c7 c16"><span class="c5"></span></p>
	<p class="c0"><span class="c2">In Short:</span><span class="c12 c19">&nbsp; Under the UK GDPR, you have rights that
			allow you greater access to and control over your personal information. You may review, change, or terminate
			your account at any time.</span></p>
	<p class="c0 c7"><span class="c5"></span></p>
	<p class="c0"><span class="c6">You have certain rights under applicable data protection laws. These include the
			right:</span></p>
	<ol class="c10 lst-kix_dqzwzg7jdyd0-0 start" start="1">
		<li class="c0 c15 li-bullet-1"><span class="c6">to request access and obtain a copy of your personal
				information;</span></li>
		<li class="c0 c15 li-bullet-1"><span class="c6">to request rectification or erasure;</span></li>
		<li class="c0 c15 li-bullet-1"><span class="c6">to restrict the processing of your personal information; and if
				applicable, to data portability. </span></li>
		<li class="c0 c15 li-bullet-1"><span class="c6">the right to object to the processing of your personal
				information. </span></li>
	</ol>
	<p class="c0 c7 c24"><span class="c6"></span></p>
	<p class="c0"><span class="c6">To make such a request, please use the contact details provided below. We will
			consider and act upon any request in accordance with applicable data protection laws.</span></p>
	<p class="c0 c7"><span class="c5"></span></p>
	<p class="c0"><span class="c12">If we are relying on your consent to process your personal information, you have the
			right to withdraw your consent at any time. Please note however that this will not affect the lawfulness of
			the processing before its withdrawal, nor will it affect the processing of your personal information
			conducted in reliance on lawful processing grounds other than consent.</span></p>
	<p class="c0"><span class="c12">&nbsp;</span></p>
	<p class="c0"><span class="c6">If you believe we are unlawfully processing your personal information, you also have
			the right to complain to your local data protection supervisory authority, which in this case is the
			Information Commissioner&rsquo;s Office. </span></p>
	<p class="c0 c7"><span class="c5"></span></p>
	<p class="c20 c7"><span class="c5"></span></p>
	<ol class="c10 lst-kix_eiwkn8wu108-0" start="7">
		<li class="c0 c14 li-bullet-0"><span class="c1">CONTROLS FOR DO-NOT-TRACK FEATURES</span></li>
	</ol>
	<p class="c0 c7 c16"><span class="c5"></span></p>
	<p class="c0"><span class="c12">Most web browsers and some mobile operating systems and mobile applications include
			a Do-Not-Track (&quot;DNT&quot;) feature or setting you can activate to signal your privacy preference not
			to have data about your online browsing activities monitored and collected. At this stage no uniform
			technology standard for recognizing and implementing DNT signals has been finalised. As such, we do not
			currently respond to DNT browser signals or any other mechanism that automatically communicates your choice
			not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we
			will inform you about that practice in a revised version of this privacy notice.&nbsp;</span></p>
	<p class="c7 c20"><span class="c5"></span></p>
	<ol class="c10 lst-kix_eiwkn8wu108-0" start="8">
		<li class="c0 c14 li-bullet-0"><span class="c1">DO WE MAKE UPDATES TO THIS NOTICE?&nbsp; &nbsp; &nbsp;</span>
		</li>
	</ol>
	<p class="c0 c7 c16"><span class="c1"></span></p>
	<p class="c0"><span class="c2">In Short: </span><span class="c19 c12">&nbsp;Yes, we will update this notice as
			necessary to stay compliant with relevant laws.</span></p>
	<p class="c0 c7"><span class="c5"></span></p>
	<p class="c0"><span class="c12">We may update this privacy notice from time to time. The updated version will be
			indicated by an updated &quot;Revised&quot; date and the updated version will be effective as soon as it is
			accessible. If we make material changes to this privacy notice, we may notify you either by prominently
			posting a notice of such changes or by directly sending you a notification. We encourage you to review this
			privacy notice frequently to be informed of how we are protecting your information.</span></p>
	<p class="c20 c7"><span class="c5"></span></p>
	<ol class="c10 lst-kix_eiwkn8wu108-0" start="9">
		<li class="c0 c14 li-bullet-0"><span class="c1">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?&nbsp; &nbsp;
				&nbsp;</span></li>
	</ol>
	<p class="c0 c7 c16"><span class="c5"></span></p>
	<p class="c0"><span class="c12">If you have questions or comments about this notice, you may email us at
			noelle</span><span class="c12">@threedegrees.world.</span></p>
	<p class="c0 c7"><span class="c5"></span></p>
	<ol class="c10 lst-kix_eiwkn8wu108-0" start="10">
		<li class="c0 c14 li-bullet-0"><span class="c1">HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
				YOU?&nbsp; &nbsp; &nbsp;</span></li>
	</ol>
	<p class="c0 c7 c16"><span class="c5"></span></p>
	<p class="c0"><span class="c12">Based on the applicable laws of your country, you may have the right to request
			access to the personal information we collect from you, change that information, or delete it in some
			circumstances. To request to review, update, or delete your personal information, please email
			noelle@threedegrees.world. We will respond to your request within 30 days.</span></p>
	<p class="c0 c7"><span class="c1"></span></p>
	<p class="c4"><span class="c25 c27"></span></p>
		</div>
	
</template>

<script>

export default {
	name: "Welcome",
	data() {
		return {
			
		};
	},	
	methods: {
		
		onLoad: function () {
			
		},
	},
	mounted: function () {
		this.onLoad();
	},
};
</script>

<style scoped>

#app
{
	background-color: #D7D1C4 !important;
}

#narrow-content{
	background-color: #ffffff !important;
}

.btn-link
{
	color: #000000 !important;
}

</style>