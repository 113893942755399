<template>
<div id="fullWidthContent" class="filledBg" :style="'min-height:' + this.$parent.heightAfterHeaderFooter + 'px'">

	<div class="margined" v-if="member">

		<b-row class="mt-2" v-if="!editMode && admin">
			<b-col>
				<b-form-group id="input-group-1" label="Create on behalf of:" label-for="onBehalfOf">
					<b-input-group>
						<b-form-input :disabled="onBehalfOfUserId" v-model="onBehalfOf" placeholder="Enter an email to create on behalf of that user, leave blank to create as you."></b-form-input>
						<b-input-group-append>
							<b-button variant="outline-success" v-if="!onBehalfOfUserId"><b-icon icon="search" @click="findOnBehalfOf" /></b-button>
							<b-button variant="outline-danger" v-if="onBehalfOfUserId"><b-icon icon="x-square-fill" @click="onBehalfOf = null; onBehalfOfUserId = null; getMember()"/></b-button>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
			</b-col>
		</b-row>
		
		
		<div v-if="editMode && listing" class="margin-bottom">
			<h2>Set Availability</h2>
			<p>Your property is currently set to <strong>{{ listing.availability.available ? 'available' : 'unavailable' }}</strong>.</p>
			<b-row class="centered">

					<b-col class="mt-2"><b-button @click="setAvailable(true)" variant="outline-primary" class="no-width-set">Available</b-button></b-col>

					<b-col class="mt-2"><b-button @click="setAvailable(false)" variant="outline-primary" class="no-width-set">Unavailable</b-button></b-col>


			</b-row>
			<b-row class="mt-2" v-if="listing">
				<b-col>
					<b-form-group id="input-group-1" label="Available From:" label-for="startDate">
						<b-form-input id="startDate" v-model="listing.availability.startDate" type="date" required></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>

			<b-row class="mt-2" v-if="listing">
				<b-col>
					<b-form-group id="input-group-1" label="Available Until:" label-for="endDate">
						<b-form-input id="endDate" v-model="listing.availability.endDate" type="date" required></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>
			
		</div>
		
		<h2 class="mt-3" v-if="!editMode">Create a new listing for {{ member.person.firstName }}.</h2>
		<h2 class="mt-3" v-if="editMode">Edit Listing.</h2>

		<b-form>
			<p>* All fields are required</p>

			<b-row>
				<b-col>
					<b-form-group id="input-group-1" label="Available to:" label-for="homeSwap">
						<b-form-checkbox v-model="listing.forRent" name="homeSwap">&nbsp;Rent</b-form-checkbox>
						<b-form-checkbox v-model="listing.forSwap" name="homeSwap">&nbsp;House Swap</b-form-checkbox>
					</b-form-group>
				</b-col>
				<b-col>
					<b-form-group id="input-group-1" label="Property Type:" label-for="propertyType">
						<b-form-radio-group>
							<b-form-radio v-model="listing.property.propertyType" name="propertyType" value="0">&nbsp;Room</b-form-radio>
							<b-form-radio v-model="listing.property.propertyType" name="propertyType" value="1">&nbsp;Studio</b-form-radio>
							<b-form-radio v-model="listing.property.propertyType" name="propertyType" value="2">&nbsp;Apartment</b-form-radio>
							<b-form-radio v-model="listing.property.propertyType" name="propertyType" value="3">&nbsp;Duplex</b-form-radio>
							<b-form-radio v-model="listing.property.propertyType" name="propertyType" value="4">&nbsp;House</b-form-radio>
						</b-form-radio-group>
					</b-form-group>
				</b-col>
			</b-row>
			
			<!-- <b-form-group id="input-group-1" label="Title:" label-for="propertyTtl" description="">
				<b-form-input id="propertyTtl" v-model="listing.property.title" type="text" placeholder="E.g. A beautiful flat in leafy fulham" required></b-form-input>
			</b-form-group>
			<b-form-group id="input-group-1" label="Strapline:" label-for="strapline" description="">
				<b-form-input id="strapline" v-model="listing.property.strapline" type="text" placeholder="E.g. Close to local amenities and tube station" required></b-form-input>
			</b-form-group> -->
			
			<!-- <b-form-group id="input-group-1" label="How do you use this property?:" label-for="propertyUsage" description="">
				<b-form-select v-model="listing.property.propertyUsage" :options="usage"></b-form-select>
			</b-form-group>

			<b-form-group id="input-group-1" label="Who is in this property?:" label-for="residency" description="">
				<b-form-select v-model="listing.property.residencyType" :options="residency"></b-form-select>
			</b-form-group> -->

			<b-row cols="2">
				<b-col>
					<b-form-group id="input-group-1" label="Bedrooms:" label-for="bedrooms" description="">
						<b-form-input id="bedrooms" v-model="listing.property.bedrooms" type="number" required></b-form-input>
					</b-form-group>
				</b-col>
				<b-col>
					<b-form-group id="input-group-1" label="Bathrooms:" label-for="bathrooms" description="">
						<b-form-input id="bathrooms" v-model="listing.property.bathrooms" type="number" required></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>
			<b-form-group id="input-group-1" label="About the bedrooms:" label-for="bedroomsDescription" description="">
				<b-form-input id="bedroomsDescription" v-model="listing.property.bedroomsDescription" type="text" required placeholder="E.g. Two double bedrooms"></b-form-input>
			</b-form-group>
			<b-form-group id="input-group-1" label="About the bathrooms:" label-for="bathroomsDescription" description="">
				<b-form-input id="bathroomsDescription" v-model="listing.property.bathroomsDescription" type="text" required placeholder="E.g. Two bathrooms, one with shower and one with bath"></b-form-input>
			</b-form-group>
			<b-form-group id="input-group-1" label="How is your home furnished?:" label-for="residency" description="">
				<b-form-select v-model="listing.property.furnishingType" :options="furnishing"></b-form-select>
			</b-form-group>
			<b-form-group id="input-group-1" label="Location feature:" label-for="locationFeature" description="">
				<b-form-input id="locationFeature" v-model="listing.property.locationFeature" type="text" required placeholder="e.g. 5 minutes walk from Highbury & Islington station"></b-form-input>
			</b-form-group>
			<b-row>
				<b-col>
					<b-form-group id="input-group-1" label="Address Line 1:" label-for="line1">
						<b-form-input id="line1" v-model="listing.property.address.line1" type="text" placeholder="XXXX XXX" required></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<b-form-group id="input-group-1" label="Post Code:" label-for="postCode">
						<b-form-input id="postCode" v-model="postCode" type="text" placeholder="XXXX XXX" required></b-form-input>
					</b-form-group>
				</b-col>
				<b-col cols="7">
					<b-form-group id="input-group-1" label="Town:" label-for="town">
						<b-form-input id="town" v-model="listing.property.address.town" type="text" placeholder="" required></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>
			
			<div class="map">
				<GmapMap v-if="listing" :options="{
						zoomControl:true,  
						mapTypeControl: false,
						scaleControl: true,
						streetViewControl: true,
						rotateControl: false,
						fullscreenControl: true,
						disableDefaultUi: false
					}" :center="{ lat:parseFloat(listing.property.address.lat), lng:parseFloat(listing.property.address.lng) }" :zoom="15" style="width: 100%; height: 250px" 
				>
					<GmapMarker
						:key="index"
						:position="{ lat:parseFloat(listing.property.address.lat), lng:parseFloat(listing.property.address.lng) }"
						:draggable=true
						@dragend="markerDragged"
					/>
				</GmapMap>
			</div>

			<b-row>
				<b-col>
					<b-form-group id="input-group-1" label-for="outsideUk">
						<b-form-checkbox v-model="listing.property.address.outsideUK" name="outsideUk">&nbsp;This property is outside of the UK</b-form-checkbox>
					</b-form-group>
				</b-col>
			</b-row>

			<b-row class="mt-2" v-if="!editMode">
				<b-col>
					<b-form-group id="input-group-1" label="Available From:" label-for="startDate">
						<b-form-input id="startDate" v-model="listing.availability.startDate" type="date" placeholder="XXXX XXX" required></b-form-input>
					</b-form-group>
				</b-col>
				<b-col>
					<b-form-group id="input-group-1" label="Available Until:" label-for="endDate">
						<b-form-input id="endDate" v-model="listing.availability.endDate" type="date" placeholder="XXXX XXX" required></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<b-form-group id="input-group-1" label="Notes on availability:" label-for="notesOnAvailability">
						<b-form-input id="notesOnAvailability" v-model="listing.availability.notes" type="text" placeholder="e.g Two months, with option to extend" required></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>
			<!-- <b-row>
				<b-col>
					<b-form-group id="input-group-1" label="Min Stay (months):" label-for="minStay">
						<b-form-input id="minStay" v-model="listing.availability.minStay" type="number" placeholder="" required></b-form-input>
					</b-form-group>
				</b-col>
				<b-col>
					<b-form-group id="input-group-1" label="Max Stay (months):" label-for="maxStay">
						<b-form-input id="maxStay" v-model="listing.availability.maxStay" type="number" placeholder="" required></b-form-input>
					</b-form-group>
				</b-col>
			</b-row> -->

			<b-row class="mt-2 mb-3">
				<b-col>
					<p class="mb-0">Add Images (portrait please):<br/><small>Please note that when uploading iPhone images previews will not be shown as images need to be processed on our servers.</small></p>
					<Uploader class="mt-2" 
						:server="this.$config.ApiUri + '/Customers/00000000-0000-0000-0000-000000000000/Listings/Photos'"
						@change="changeMedia"
						:max-filesize="10"
					/>
				</b-col>
			</b-row>

			<div v-if="editMode">
				<p>Current Images (tap for options):</p>
				<span v-for="p in listing.property.photos" :key="p.id">
					<b-img :id="'tooltip-target-' + p.id" class="existing-image" :src="p.uri"></b-img>
					<b-tooltip triggers="hover" :target="'tooltip-target-' + p.id"><b-button variant="link" @click="removePhoto(p.id)">Remove</b-button><b-button variant="link" @click="setMainImage(p.id)">Set As Main</b-button></b-tooltip>
				</span>
			</div>

			<div style="clear:both"></div>

			<b-form-group id="input-group-1" label="Description:" label-for="description" description="">
				<b-form-textarea id="description" v-model="listing.property.description" type="text" required></b-form-textarea>
			</b-form-group>

			<b-row>
				<!-- TODO ADD Field Dropdown for currency listing.pricing.currencySymbol-->
				<b-col>
					<b-form-group id="input-group-1" label="Price:" label-for="price">
						<b-input-group>
							<template #prepend>
								<b-form-select :options="currencies" v-model="listing.pricing.currencySymbol" variant="outline-secondary"></b-form-select>
							</template>
							<b-form-input id="price" v-model="listing.pricing.price" type="number" placeholder="" required></b-form-input>
						</b-input-group>
					</b-form-group>
				</b-col>
				<b-col>
					<b-form-group id="input-group-1" label="Per:" label-for="priceType">
						<b-form-radio v-model="listing.pricing.priceType" name="priceType" value="0">&nbsp;Week</b-form-radio>
						<b-form-radio v-model="listing.pricing.priceType" name="priceType" value="1">&nbsp;Month</b-form-radio>
					</b-form-group>
				</b-col>
			</b-row>
			
			<b-row>
				<b-col>
					<b-form-group id="input-group-1" label="Bills Included:" label-for="priceType">
						<b-form-radio v-model="listing.pricing.billsIncuded" name="billsInc" value="false">&nbsp;No</b-form-radio>
						<b-form-radio v-model="listing.pricing.billsIncuded" name="billsInc" value="true">&nbsp;Yes</b-form-radio>
					</b-form-group>
				</b-col>

			</b-row>

			<b-form-group id="input-group-1" label="Key Amenities:" label-for="propertyFeatures">
				<b-form-checkbox-group 
					v-model="listing.property.features"
					:options="features"
					class="mb-3"
					value-field="name"
					text-field="name">
				</b-form-checkbox-group>
			</b-form-group>

			<b-form-group id="input-group-1" label="House Rules:" label-for="houseRules">
				<b-form-checkbox-group 
					v-model="listing.property.houseRules"
					:options="houseRules"
					class="mb-3"
					value-field="name"
					text-field="name">
				</b-form-checkbox-group>
			</b-form-group>

			<p class="mb-0">Local Favourites<br/>(optional - if not we can add for you):</p>
			<b-form-group id="input-group-1" label="Where do you get your morning coffee?:" label-for="coffee">
				<b-form-input id="coffee" v-model="listing.property.localCoffee" type="text" placeholder="" required></b-form-input>
			</b-form-group>
			<b-form-group id="input-group-1" label="Local pub?:" label-for="pub">
				<b-form-input id="pub" v-model="listing.property.pub" type="text" placeholder="" required></b-form-input>
			</b-form-group>
			<b-form-group id="input-group-1" label="Favourite restaurant?:" label-for="resturant">
				<b-form-input id="resturant" v-model="listing.property.resturant" type="text" placeholder="" required></b-form-input>
			</b-form-group>
			<b-form-group id="input-group-1" label="Hidden gem?:" label-for="gem">
				<b-form-input id="gem" v-model="listing.property.gem" type="text" placeholder="" required></b-form-input>
			</b-form-group>

			<div v-if="admin">
				<b-row class="mb-3">
					<b-col>
						<p>Add to Collections:</p>
						<b-button style="margin-right:5px" size="sm" variant="success" v-for="c in collections" :key="c.id" @click="addToCollection(c)">+ {{c.name}}</b-button>
					</b-col>
				</b-row>	
				<b-row class="mb-3">
					<b-col>
						<p>Remove from Collections:</p>
						<b-button style="margin-right:5px" size="sm" variant="warning" v-for="c in listing.collectionNames" @click="removeFromCollection(c)" :key="c.id">- {{c}}</b-button>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<b-form-group id="input-group-1" label-for="approved">
							<b-form-checkbox v-model="listing.approved" name="approved">&nbsp;Approved</b-form-checkbox>
						</b-form-group>
					</b-col>
					<b-col v-if="editMode"><b-button @click="deleteListing" variant="danger">Delete Listing</b-button></b-col>
				</b-row>	
			</div>			

			<div class="margined centered more-margin-top more-margin-bottom">
				<b-alert :show="saved" variant="success">{{ message }}</b-alert>
				<b-button :disabled="saving" @click="save" variant="outline-primary">{{ editMode? "Save" : "Create" }}</b-button>
			</div>
			
		</b-form>

	</div>

	<b-modal id="modalMessage" centered title="Create Listing" :hide-header="true">
		<h2>Please fix the following:</h2>
		<p class="my-4" v-html="message"></p>
		<template #modal-footer>
			<!-- Emulate built in modal footer ok and cancel button actions -->
			<b-button size="" variant="primary" @click="hideModal">
				OK
			</b-button>
		</template>
	</b-modal>

</div>
</template>
<script>
import Uploader from 'vue-media-upload'
export default {
  name: 'CreateListing',
  metaInfo() {
        return { 
            title: "Three Degrees - Create Listing",
        }
    },
  components: {
	Uploader
  },
  data() {
	return{
		saving: false,
		postCode:"",
		media:[],
		listingId: null,
		editMode: false,
		member: null,
		features: null,
		searchQuery: "",
		message: "",
		saved: false,
		collections:null,
		selectedFeatures: [],
		onBehalfOf:null,
		onBehalfOfUserId:null,
		usage: [
			{ value: 1, text: 'Primary residence' },
			{ value: 2, text: 'Vacation Home' },
			{ value: 3, text: 'Investment Home (Airbnb, short term rental)' }
        ],
		residency: [
			{ value: 1, text: "It's Vacant" },
			{ value: 2, text: 'I live alone' },
			{ value: 3, text: 'I live alone (with a furry friend!)' },
			{ value: 4, text: 'I live with a significant other/partner' },
			{ value: 5, text: 'I live with a housemate (s)' },
			{ value: 6, text: 'I live with my children' },
		],
		furnishing: [
			{ value: 0, text: "Fully" },
			{ value: 1, text: 'Part' },
			{ value: 2, text: 'None' }
		],
		houseRules: [ { name: "Children welcome"}, { name: "No smoking" }, { name: "No pets" } ],
		currencies: [
			{ value: "£", text: '£' },
			{ value: "$", text: '$' },
			{ value: "€", text: '€' }
		],
		listing: {
			"property": {
				"title": "",
				"strapline": "",
				"propertyUsage": 0,
				"residencyType": 0,
				"furnishingType": 0,
				"description": "",
				"bedroomsDescription": "",
				"bathroomsDescription": "",
				"photos": [],
				"address": {"postCode": "", "town": ""},
				"propertyType": null,
				"locationFeature": "",
				"bedrooms": 0,
				"bathrooms": 0,
				"features": []
			},
			"pricing": {
				"price": 0,
				"currencySymbol": "£",
				"priceType": 0,
				"billsIncuded": true
			},
			"availability": {
				"startDate": null,
				"endDate": null,
				"minStay": 0,
				"maxStay": 0,
				"minStayUnits": 0,
				"maxStayUnits": 0
			}
		},
		admin: false
	}
  },
  watch: 
  {
	postCode(newVal) {
		this.addressLookup(newVal);
	}
  },
  methods: {
	markerDragged: function(location) {
		this.listing.property.address.lat = location.latLng.lat();
		this.listing.property.address.lng = location.latLng.lng();
	},
	hideModal: function() {
		this.$bvModal.hide("modalMessage");
	},
	addToCollection: function(c) {
		this.$http.post(this.$config.ApiUri + "/Collections/" + c.id + "/Listings/" + this.listing.id, null)
			.then(() => {
				this.onLoad();
			}
		)
	},
	removeFromCollection: function(c) {
		for(var x =0 ; x < this.collections.length; x++) {
			if (this.collections[x].name == c) {
				this.$http.delete(this.$config.ApiUri + "/Collections/" + this.collections[x].id + "/Listings/" + this.listing.id, null)
					.then(() => {
						this.onLoad();
					}
				)
			}
		}
	},
	getMember: function(){
		var destUserId = "00000000-0000-0000-0000-000000000000";
		if (this.onBehalfOfUserId)
		{
			destUserId = this.onBehalfOfUserId;
		}
		this.$http.get(this.$config.ApiUri + "/Customers/" + destUserId)
			.then((result) => {
				this.member = result.data;
			}
		);
		if (this.$route && this.$route.meta && this.$route.meta.admin) {
			this.admin = true;
		}
	},
	changeMedia(media){
		for(var x = 0; x < media.length; x++)
		{
			if (media[x].name == null){
				this.message = "Sorry that image is invalid.  It's possibly too small.";
				media.splice(x, 1);
				this.$bvModal.show("modalMessage"); 
				break;
			}
		}
		this.media = media;
	},
	getFeatures: function() {
		this.$http.get(this.$config.ApiUri + "/Features")
			.then((result) => {
				this.features = result.data;
			}
		)
	},
	getCollections: function() {
		this.$http.get(this.$config.ApiUri + "/Collections?lite=true")
			.then((result) => {
				this.collections = result.data;
			}
		)
	},
	deleteListing: function() {
		this.$http.delete(this.$config.ApiUri + "/Customers/00000000-0000-0000-0000-000000000000/Listings/" + this.$route.params.id)
			.then(() => {
				this.$router.back();this.$router.back();
			}
		)
	},
	onLoad() {
		this.getMember();
		this.listingId = this.$route.params.id;
		if (this.listingId != null)
		{
			this.editMode = true;
			this.$http.get(this.$config.ApiUri + "/Listings/" + this.$route.params.id)
				.then((result) => {
					this.listing = result.data;
					this.postCode = this.listing.property.address.postCode;
					if (this.listing.availability == null)
					{
						this.listing.availability = {
							"startDate": null,
							"endDate": null,
							"minStay": 0,
							"maxStay": 0,
							"minStayUnits": 0,
							"maxStayUnits": 0
						};
					}
					else
					{
						if (this.listing.availability.startDate)
						{
							this.listing.availability.startDate = this.listing.availability.startDate.split('T')[0];
						}
						if (this.listing.availability.endDate)
						{
							this.listing.availability.endDate = this.listing.availability.endDate.split('T')[0];
						}
					}
				}
			)
		}
		//this.$config.ApiUri = "https://localhost:5001";
		this.getFeatures();
		this.getCollections();
	},
	validateInput: function() {
		this.message = "";
		// if (this.listing.property.title == "") { this.message += "<br/>Please enter a title"; } 
		// if (this.listing.property.strapline == "") { this.message += "<br/>Please enter a strapline";  }
		if (!this.listing.forRent && !this.listing.forSwap){ this.message += "<br/>Please choose rent, home swap or both"; }
		if (this.listing.property.propertyType == null) { this.message += "<br/>Please choose your property type";  }
		if (this.listing.property.bathrooms == 0) { this.message += "<br/>Please enter the number of bathrooms";  }
		if (this.listing.property.bedrooms == 0) { this.message += "<br/>Please enter the number of bathrooms";  }
		if (this.message != "") 
		{
			this.$bvModal.show("modalMessage"); 
			return false;
		}
		return true;
	},
	setAvailable: function(avail) {
		var availability = { available: avail };
		this.$http.post(this.$config.ApiUri + "/Customers/00000000-0000-0000-0000-000000000000/Listings/" + this.listing.id + "/SetAvailabilityRequests", availability)
			.then(() => {
				this.listing.availability.available = avail;
			}
		)
	},
	findOnBehalfOf: function() {
		this.$http.get(this.$config.ApiUri + "/Customers/?q=" + this.onBehalfOf)
			.then((result) => {
				if (result.data.length > 1)
				{
					this.message = "More than one user found, please narrow your search";
					this.$bvModal.show("modalMessage");
					return;
				}
				if (!result.data || result.data.length == 0)
				{
					this.message = "No users found.";
					this.$bvModal.show("modalMessage");
					return;
				}
				if (result.data[0].id == this.member.id)
				{
					this.message = "Erm . . . That's you . . .";
					this.$bvModal.show("modalMessage");
					return;
				}
				this.onBehalfOfUserId = result.data[0].id;
				this.onBehalfOf = result.data[0].person.email;
				this.getMember();
			}
		)
	},
	showSaved: function() {
		this.message = "Saved!"
		this.saved = true;
		this.interval = setInterval(() => this.saved = false, 4000);
	},
	save: function()
	{
		try {
			if (!this.validateInput()){ return; }
			this.listing.property.propertyType = parseInt(this.listing.property.propertyType);
			this.listing.pricing.priceType = parseInt(this.listing.pricing.priceType);
			this.listing.pricing.billsIncuded = this.listing.pricing.billsIncuded == "true";
			if (this.listing.availability.endDate == '') { this.listing.availability.endDate = null; }
			this.saving = true;
			if (this.listingId == null) {
				this.addListing();
			}
			else {
				this.updateListing();
			}
		} catch(ex) {
			this.message = "Sorry, something has gone wrong.  Please screenshot this error and send it to the ThreeDegrees team: " + ex; 
			this.$bvModal.show("modalMessage"); 
			return false;
		}
	},
	removePhoto: function(photoId){
		this.$http.delete(this.$config.ApiUri + "/Customers/" + this.listing.customerId + "/Listings/" + this.listingId + "/Photos/" + photoId)
			.then(() => {
				for(var x = 0; x < this.listing.property.photos.length; x++) {
					if (this.listing.property.photos[x].id == photoId) {
						this.listing.property.photos.splice(x, 1);
						break;
					}
				}
			}
		)
	},
	setMainImage: function(photoId){
		for(var x = 0; x < this.listing.property.photos.length; x++) {
			if (this.listing.property.photos[x].id == photoId) {
				this.listing.property.photos[x].mainImage = true;
				var data = this.listing.property.photos[x];
				this.listing.property.photos.splice(x, 1);
				this.listing.property.photos.unshift(data);
				break;
			}
			else
			{
				this.listing.property.photos[x].mainImage = false;
			}
		}
	},
	showError: function(error) {
		this.message = "Sorry, something has gone wrong.  " +
			"Please screenshot this error and send it to the ThreeDegrees team: " + 
			JSON.stringify(error.response.data.errors); 
		this.$bvModal.show("modalMessage"); 
		this.saving = false;
	},
	updateListing: function()
	{
		for(var x = 0; x < this.media.length; x++)
		{
			//if (this.media[x].uri != null){
				this.listing.property.photos.push({ "uri": this.media[x].name });
			//}
		}
		this.$http.put(this.$config.ApiUri + "/Customers/" + this.listing.customerId + "/Listings/" + this.listingId, this.listing)
			.then(() => {
				this.$router.push("/memberhome");
			}
		)
		.catch((error) => { this.showError(error); })
	},
	addListing: function(){
		for(var x = 0; x < this.media.length; x++)
		{
			this.listing.property.photos.push({ "uri": this.media[x].name });
		}
		var destUserId = "00000000-0000-0000-0000-000000000000";
		if (this.onBehalfOfUserId)
		{
			destUserId = this.onBehalfOfUserId;
		}
		this.$http.post(this.$config.ApiUri + "/Customers/" + destUserId + "/Listings", this.listing)
			.then(() => {
				this.$router.push("/memberhome");
			}
		)
		.catch((error) => { this.showError(error); })
	},
	addressLookup: function(postCode){
		if (postCode.length >= 5)
		{
			this.listing.property.address.postCode = postCode;
			var lookup = { postCode: postCode };
			this.$http.post(this.$config.ApiUri + "/Addresses/AddressLookupRequests", lookup)
				.then((result) => {
					this.listing.property.address.postCode = result.data.addresses[0].postCode;
					//this.listing.property.address.town = result.data.addresses[0].town;
					this.listing.property.address.lat = result.data.addresses[0].lat;
					this.listing.property.address.lng = result.data.addresses[0].lng;
				}
			)
		}
	}
  },
  mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

.mu-plus-icon
{
	color: #333333 !important;
}

.existing-image
{
	max-width: 100px;
	float: left;
	margin: 3px;
	border-radius: 5%;
}

.btn-link {
    color: #ffffff !important;
}

</style>