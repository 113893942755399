<template>
<div id="fullWidthContent" class="filledBg" :style="'min-height:' + this.$parent.heightAfterHeaderFooter + 'px'">

	<div class="margined">
		<h2>Collections</h2>
		<div v-for="g in collections" :key="g.id">
			<b-row>
				<b-col>
					<b-button variant="link" :href="'#/collectionAdmin/'+ g.id" >{{ g.name }}</b-button> 

				</b-col>
				<b-col>
					<b-button variant="link" :href="'#/collections/'+ g.id">preview</b-button>

				</b-col>
			</b-row>
		</div>
		<br/>
		<b-button @click="newCollection">New</b-button>
	</div>

</div>
</template>
<script>
//import AreaGuide from './AreaGuide.vue'
export default {
  name: 'CollectionsAdmin',
  metaInfo() {
        return { 
            title: "Three Degrees Admin - Collections",
        }
    },
  components: {
	//AreaGuide
  },
  data() {
	return{
		collections: null		
	}
  },
  methods: {
	hideModal: function() {
		this.$bvModal.hide("modalMessage");
	},
	getCollections: function(){
		this.$http.get(this.$config.ApiUri + "/Collections?onlyEnabled=false")
			.then((result) => {
				this.collections = result.data;
			}
		)
	},
	newCollection: function() {
		this.$router.push("CollectionAdmin/-");
	},
	onLoad() {
		this.getCollections();
	}
  },
  mounted: function() { this.onLoad(); }
}
</script>

<style scoped>



</style>