<template>
<div id="fullWidthContent" class="filledBg" :style="'min-height:' + this.$parent.heightAfterHeader + 'px'">
	<b-button class="backNav" @click="$router.back()" size="xs" variant="link"><b-icon-caret-left-fill /></b-button>

	<div v-if="chat" class="centered" style="padding-top:20px">
		<b-avatar :href="'#/members/' + chat.withCustomer.id" :src="chat.imageUri" :text="chat.withCustomer.person.firstName[0] + chat.withCustomer.person.lastName[0]" size="5rem"></b-avatar><br/>
		{{ chat.withCustomer.person.firstName }} {{ chat.withCustomer.person.lastName }}
	</div>

	<div class="messages-panel" ref="messages">

		<div :class="'message message-' + (m.isInbound ? 'in' : 'out') + ' m' + index + 'of' + chat.messages.length" v-for="(m, index) in chat.messages" :key="m.id">
			{{ m.body }}<br/>
			<div>{{ m.createDateTimeString }}</div>
		</div>

		<div ref="lastMessage"></div>

	</div>

	<div class="send-area" ref="sendArea">
		<b-input-group>
			<b-form-textarea @focus="forceScroll()" name="message" id="message"
				data-lpignore="true" autocomplete="false" 
				v-model="message.body">
			</b-form-textarea>
			<template #append>
				<b-button :disabled="message.body == ''" @click="sendMessage" variant="success"><b-icon icon="play-fill"></b-icon></b-button>
			</template>
		</b-input-group>
	</div>

</div>
</template>
<script>

import Services from '../mixins/Services';

export default {
  name: 'Chat',
  components: {
  },
  mixins: [Services],
  metaInfo() {
        return { 
            title: "Three Degrees - Chat",
        }
    },
  data() {
	return{
		chat: null,
		message: {body:""},
		interval:null
	}
  },
  methods: {

	sendMessage: function() {

		if (this.message.body == "" || this.message.body == null) { return; }
		this.message.subject = this.chat.messages[0].subject;
		this.message.toCustomerId = this.$route.params.id;
		this.message.fromCustomerId = this.self.id;
		this.message.senderArchived = false;
		this.message.recipientArchived = false;
		this.message.id = null;
		this.message.createDateTime = null;
		this.$http.post(this.$config.ApiUri + "/Customers/" + this.$route.params.id + "/Enquiries", this.message)
			.then(() => {
				this.getChat(this.$route.params.id);
				this.message.body = "";
			}
		)
	},

	forceScroll: function() {
		setTimeout(() => 
			this.$refs["lastMessage"].scrollIntoView({
				behavior: 'smooth',
			})
		, 200);
	},

	getMember: function(){
		this._getSelf(null, () => { this.getChat(this.$route.params.id); })
	},

	getChat: function(id){
		this.$http.get(this.$config.ApiUri + "/Customers/00000000-0000-0000-0000-000000000000/Chats?withCustomerId=" + id)
			.then((result) => {
				this.chat = result.data[0];
				this.forceScroll();
			}
		)
	},

	onLoad() {
		this.getMember();
		this.interval = setInterval(() => this.getChat(this.$route.params.id), 10000);
	},

	onUnLoad() {
		clearInterval(this.interval);
	}

  },

  mounted: function() { this.onLoad(); },
  destroyed:  function() { this.onUnLoad(); }

}
</script>

<style scoped>

.send-area
{
	bottom:0px;
	margin:10px;
	position: fixed;
	width: 95%;
	z-index: 9999;
}

.messages-panel {
	margin-top: 20px;
	margin-bottom: 100px;
}

.message {
	padding: 8px;
	border-radius: 10px !important;
	margin-top: 10px;
	margin-left: 20px;
	margin-right: 20px;
	background-color: #cff3bf;
}
.message div {
	font-size: 10px;
	text-align: right;
}

.message-in{
	margin-right: 60px;
	background-color: #ffffff;
}

.message-out {
	margin-left: 60px;
}

.btn-link
{
	color: #000000 !important;
}

.m0of15
{
	opacity: 0.4;
}

.m1of15
{
	opacity: 0.6;
}

.m2of15
{
	opacity: 0.8;
}
	
</style>