<template>
  <div id="app" :style="$route.meta.fullWidth ? '' : 'max-width: 1000px;'">
	
		<Menu />
		<div id="outer">
			<router-view :key="$route.fullPath"></router-view>
		</div>
		<Footer />
	
  </div>
</template>

<script>

import Menu from './components/Menu.vue'
import Footer from './components/Footer.vue'

export default {
	name: 'App',
	properties:{
	},
	components: {
		Menu, Footer
	},
	data() {
		return{
			numbers: [],
			numberTimeout: null,
			height:null,
			heightAfterHeader: null,
			heightAfterHeaderFooter: null
		}
	},
	methods: {
		queueContact: function(number)
		{
			this.numbers.push(number);
			if (this.numberTimeout) { clearTimeout(this.numberTimeout); }
			this.numberTimeout = setTimeout(() => this.uploadNumbers(), 10000);
		},
		uploadNumbers: function()
		{
			this.$http.post(this.$config.ApiUri + "/Customers/00000000-0000-0000-0000-000000000000/Contacts", { numbers: this.numbers })
				.then(() => {
					this.numbers = [];
				}
			)
		}
	},
	beforeCreate: function() {
		if (this.$cookies.get('token') != null) {
			this.$http.defaults.headers.common = {
				"Authorization": "bearer " + this.$cookies.get('token')
			};
		}
		else {
			if (this.$route.meta.authenticate) {
				this.$router.push("login?route=" + this.$route.path);
			}
		}
	},
	beforeMount(){
    //Create interface between Webview and Native application
        window['App'] = {
            components:  this,
            queueContact: (number) =>  this.queueContact(number),
        };
		this.height = window.innerHeight;
		this.heightAfterHeader = this.height - 80;
		this.heightAfterHeaderFooter = this.height - 190;
    }
}
</script>

<style>

@font-face {
	font-family: "GrifintolL";
	src: url("assets/GrifinitoL-Regular.otf");
}

@font-face {
	font-family: "Inter-Regular";
	src: url("assets/Inter-Regular.ttf");
}

html
{
	background-color: #ffffff;
	min-height: 100%;
}

body
{
	min-height: 100vh !important;
	background-color: rgb(164, 189, 157) !important;
}

.max-width-restraint
{
	max-width: 700px;
	margin-right: auto;
	margin-left: auto;
}

#app {
  font-family: 'Inter-Regular', 'Montserrat', sans-serif;
  font-size: 15px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
  background-color: #ffffff;
  min-height: 100vh;
  margin-right: auto;
  margin-left: auto;
}

#content{
	margin: 20px;
	margin-top: 20px;
	max-width:1400px; 
	margin-right:auto; 
	margin-left:auto;
	padding-left: 20px;
	padding-right: 20px;
	max-width: 600px;
}

#narrow-content{
	max-width:1200px; 
	margin-right:auto; 
	margin-left:auto;
	padding-left: 40px;
	padding-right: 40px;
	background-color: #D7D1C4 !important;
	padding-bottom: 20px;
	padding-top: 20px;
}

#fullWidthContent {
	padding-top: 10px;
	background-color: #D7D1C4 !important;
	padding-bottom: 20px;
}

#outer
{
	/* min-height: 100vh; */
}

.submenu {
	background-color: #d7d1c542;
	height: 30px;
	font-size: 14px;
	margin-top: 1px;
}

.submenu a, .submenu a:hover{
	color: #222222;
	text-decoration: none;
	margin-right: 20px;
}

.navbar-nav a {
	margin-right: 20px;
}

#sidebar {
	color: white !important;
}

h1
{
	font-family: GrifintolL, 'Gloock', serif !important;
	color: black;
	font-size: 45px !important;
}

h2
{
	font-family: GrifintolL, 'Gloock', serif !important;
	color: black;
	font-size: 35px !important;
}

h3
{
	font-family: GrifintolL, 'Gloock', serif !important;
	color: black;
	font-size: 27px !important;
	margin:0px !important;
}

h1.title
{
	text-align: center !important;
	text-transform: uppercase !important;
}

h1.bigger-title
{
	text-align: center !important;
	text-transform: uppercase !important;
	font-size: 70px !important;
}

.margined {
	margin-top: 20px;
	max-width: 80%;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 20px;
}

.less-margined {
	margin-top: 20px;
	width: 85%;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 20px;
	max-width: 500px;
}

.more-margin-bottom
{
	margin-bottom: 40px;
}

.margin-top
{
	margin-top: 20px;
}

.bit-more-margin-top
{
	margin-top: 30px;
}

.more-margin-top
{
	margin-top: 40px;
}

.container.centered
{
	margin-left: auto !important;
	margin-right: auto !important;
}

.centered
{
	text-align: center !important;
}

.margin-side
{
	margin-left: 5px !important;
	margin-right: 5px !important;
}

.form-group {
	margin-top: 10px;
}

.hero, .full-width {
	width: 100%;
}

.btn-outline-primary
{
	width: 300px !important;
	max-width: 300px !important;
	border-radius: 0.9rem !important;
	color: black !important;
	border: 1px solid black !important;
	text-transform: uppercase;
}

.btn-outline-primary.no-width-set
{
	width: unset !important;
	max-width: unset !important;
}

.btn-outline-primary:hover 
{
	background-color: #d7d1c542 !important;
}

svg.mu-plus-icon
{
	color: #333333 !important;
}

a, a:hover {
	color: inherit !important;
	text-decoration: inherit !important;
}

@media all and (max-width: 700px)
{
	.btn-outline-primary{
		width: 100% !important;
		min-width: 150px !important;
	}
}

.listings-wrapper
{
	max-width: 1020px;
	margin-right:auto;
	margin-left: auto;
}

.backNav
{
	margin-right: 0px;
	margin-left:-15px;
	color: #000000;
	position:absolute;
	top: 20px;
	left: 55px;
	display:none !important;
}

@media all and (max-width: 700px)
{
	.backNav {
		display: block !important;
	}
}

.underlined {
	text-decoration: underline !important;
}

</style>
