<template>
	<div id="footer" v-if="!$route.meta.hideFooter">
		<div id="footer-inner">
			<b-row>
				<b-col class="footer-item">
					<a href="/#/privacy">Privacy</a>
				</b-col>
				<b-col class="footer-item">
					<a href="/#/support">Support</a>
				</b-col>
			</b-row>
			<b-row>
				<b-col class="footer-item">
					© Three Degrees.
				</b-col>
			</b-row>
		</div>
	</div>
</template>

<script>
export default {
 props: {
   menuMode: String
 },
 methods: {
   menuEvent(mode) {
		this.menuMode = mode;
		this.$emit('menuEvent', mode);
   }
 }
}
</script>

<style scoped>
	#footer 
	{
		background-color: white;
	}
	#footer-inner
	{
		margin-top:20px; 
		padding: 20px
	}
	.footer-item
	{
		text-align: center;
	}
</style>