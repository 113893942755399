<template>
	
	<b-modal id="linkModal" :title="Title" centered  ok-only style="width:450px;">
		<iframe style="padding: 0px; border:0px; overflow-y: hidden" height="600" width="100%" :src="LinkURL">
		</iframe>
		<template #modal-footer="{ close }">
			<b-button style="display:none" size="sm" variant="outline-danger" @click="close()">
				Close Modal
			</b-button>
			<h5 style="display:none">Modal Header</h5>
		</template>
	</b-modal>
	
</template>

<script>
export default {
	props: ['Title', 'LinkURL'],
	data() {
		return{
			index:0,
			searchQuery: ""
		}
	}
}

</script>

<style scoped>
	
</style>