export default {
	data() {
		return{
			token: "",
			error: null,
			loginOk: true,
			canGetSelf: false,
			self: null
		}
	},
	methods: {

		_tryLoginFromCookies: function(onSuccess, onFail) {
			if (this.$cookies.get("username") && this.$cookies.get("password")) {
				this._login(
					this.$cookies.get("username"), 
					this.$cookies.get("password"), 
					onSuccess ? () => { onSuccess() } : null, 
					onFail ? () => { onFail() } : null
				);
			}
			else {
				if (onFail) {
					onFail();
				}
			}
		},

		_login: function (username, password, onSuccess = null, onFail = null) {
			this.$http.post(this.$config.ApiUri + "/Sessions", {"userName": username, "password": password})
				.then((result) => {
					this.loginOk = true;
					this.token = result.data.session.token;
					this.$http.defaults.headers.common = {
						"Authorization": "bearer " + this.token
					};
					this.$cookies.set("token", this.token, "23h");
					this.$cookies.set("username", username, "60d");
					this.$cookies.set("password", password, "60d");
					if (onSuccess) {
						onSuccess();
					}
				}
			).catch(() => { 
				this.loginOk = false;
				if (onFail) {
					onFail();
				}
			})
		},

		_getSelf: function(goToUrl = null, onSuccess) {
			this.$http.get(this.$config.ApiUri + "/Customers/00000000-0000-0000-0000-000000000000")
				.then((response) => {
					this.self = response.data;
					this.canGetSelf = true;
					if (onSuccess) {
						onSuccess();
					}
					if (goToUrl){
						this.$router.push(goToUrl);
					}
				}
			).catch(() => { this.canGetSelf = false; })
		}

	}
  }