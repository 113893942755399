<!-- App Landing -->
<template>
	<div id="fullWidthContent" :style="'padding-top:0px; min-height:' + this.$parent.heightAfterHeaderFooter + 'px'"
		class="filledBg">

		<div v-if="!loaded" style="max-width: 600px; margin-right:auto; margin-left: auto; text-align: center">
			<img width="100%"  center :src="require('../assets/splash.gif')" />
		</div>

		<img :src="require('@/assets/palace.png')" style="display:none" @load="imageLoaded = true" />

		<div v-if="loaded">
			<div class="hero" :style="'background-image: url(' + require('@/assets/palace.png') + ')'"></div>

			<div class="color-block-strap">
				<h1>Welcome to the Future of Living</h1>Exceptional homes to rent and swap around the world
			</div>

			<!-- <div v-if="!listings" class="margined centered more-margin-top more-margin-bottom">
				<b-button href="/#/register" class="no-width-set"
					variant="outline-primary">Apply</b-button>&nbsp;&nbsp;<b-button href="/#/login" class="no-width-set"
					variant="outline-primary">Login</b-button>
			</div> -->

			<div v-if="listings && showListings" style="padding-bottom: 20px;">
				<div class="margined centered margin-top">
					<h1>Featured Rentals</h1>
				</div>

				<div class="listings-wrapper mt-2">
					<a v-for="l in listings" :key="l.id" :href="'/#/listings/' + l.id">
						<ListingResult v-if="l.property.address.town" :Listing="l" Lite="true"
							:ShowAvailability=true Member="self" />
					</a>
				</div>
				<div style="clear:both" />

				<div class="margined centered mt-3">
					<b-button href="/#/search" class="no-width-set" variant="outline-primary">See More
						Rentals</b-button>
				</div>

				<div class="margined centered mt-5">
					<h1>Featured Swaps</h1>
				</div>

				<div class="listings-wrapper mt-2">
					<a v-for="l in swaps" :key="l.id" :href="'/#/listings/' + l.id">
						<ListingResult v-if="l.property.address.town" :Listing="l" Lite="true"
							:ShowAvailability=true Member="self" />
					</a>
				</div>
				<div style="clear:both" />

				<div class="margined centered mt-3">
					<b-button href="/#/search" class="no-width-set" variant="outline-primary">See More Swaps</b-button>
				</div>
			</div>

			<div v-if="collections" class="color-block-strap" style="background-color: rgb(142, 164, 135);">
				<h1>Collections</h1>
			</div>
			<Collection v-for="c in collections" :key="c.id" :Collection="c" :Details="false" />

			<AreaGuide PostCode="rnd" />

		</div>


	</div>
</template>
<script>
import ListingResult from './ListingResult.vue'
import AreaGuide from './AreaGuide.vue'
import Collection from './Collection.vue'
import Services from '../mixins/Services'

export default {
	name: 'NewHome',
	metaInfo() {
		return {
			title: "Three Degrees - Home",
		}
	},
	mixins: [Services],
	components: {
		ListingResult,
		AreaGuide,
		Collection
	},
	data() {
		return {
			self: null,
			listings: null,
			swaps: null,
			collections: null,
			guide: null,
			loaded: false,
			minTime: false,
			imageLoaded: false,
			interval: null,
			showListings: true
		}
	},
	methods: {
		getMember: function () {
			this._getSelf(null, () => { this.getListings(); this.getSwaps(); this.getCollections() })
		},
		getListings: function () {
			this.$http.get(this.$config.ApiUri + "/Listings?limit=1&featured=true&random=true&onlyRental=true")
				.then((result) => {
					this.listings = result.data;
					this.loaded = true;
				}
			)
		},
		getCollections: function () {
			this.$http.get(this.$config.ApiUri + "/Collections?onlyEnabled=true")
				.then((result) => {
					this.collections = result.data;
					this.loaded = true;
				}
			)
		},
		getSwaps: function () {
			this.$http.get(this.$config.ApiUri + "/Listings?limit=1&featured=true&random=true&onlySwap=true")
				.then((result) => {
					this.swaps = result.data;
				}
			)
		},
		checkLoaded: function () {
			this.minTime = true;
			if (this.imageLoaded) { this.loaded = true; }
			if (this.loaded) {
				clearInterval(this.interval);
			}
		},
		onLoad() {
			this._tryLoginFromCookies(() => { this.getMember(); });
			this.interval = setInterval(() => this.checkLoaded(), 3000);
		}
	},
	mounted: function () { this.onLoad(); }
}
</script>

<style scoped>
.hero {
	color: white;
	height: 500px;
	background-position: center;
	background-size: cover;
	font-family: GrifintolL, 'Gloock', serif !important;
	font-size: 75px !important;
	text-align: center;
	padding: 70px;
	padding-top: 100px;
	text-transform: capitalize;
	line-height: 1.5;
}

.color-block-strap {
	color: #000000;
	background-color: rgb(232, 105, 62);
	font-family: GrifintolL, 'Gloock', serif !important;
	font-size: 35px !important;
	text-align: center;
	padding: 30px;
	line-height: 1.2;
}
</style>