<template>

	<div v-if="!$route.meta.hideNavbar">
		<b-navbar :style="'margin-left:auto; margin-right: auto; ' + (this.$route.meta.topBarColor ? 'background-color:' + this.$route.meta.topBarColor : '')" toggleable="lg" >
			<b-navbar-brand :href="(!$route.meta.disableHomeLink ?'/#/home': '')" :style="(!$route.meta.hideNavbarContent ? 'padding-left: 56px;': '') + ' '"><h1 class="title" :style="this.$route.meta.largeLogo ? 'font-size:92 !important' : ''">Three D<span style="color:rgb(232, 105, 62);" v-if="admin">e</span><span v-if="!admin">e</span>grees</h1></b-navbar-brand>

			<b-navbar-toggle v-if="!$route.meta.hideNavbarContent" target="nav-collapse"></b-navbar-toggle>

			<b-collapse v-if="!$route.meta.hideNavbarContent" id="nav-collapse" is-nav>
				<!-- Right aligned nav items -->
				<b-navbar-nav class="ml-auto">

					<b-nav-item right href="/#/home"><b-icon icon="house-door"></b-icon> Home</b-nav-item>
					<b-nav-item v-if="!this.$cookies.get('token')" right href="/#/login"><b-icon icon="lock"></b-icon> Login</b-nav-item>
					<b-nav-item v-if="!this.$cookies.get('token')" right href="/#/register"><b-icon icon="check"></b-icon> Apply</b-nav-item>
					<b-nav-item right href="/#/search"><b-icon icon="search"></b-icon> Search</b-nav-item>
					<b-nav-item v-if="this.$cookies.get('token')" right href="/#/collections"><b-icon icon="list"></b-icon> Collections</b-nav-item>
					<b-nav-item v-if="this.$cookies.get('token')" right href="/#/map"><b-icon icon="map"></b-icon> Map</b-nav-item>
					<b-nav-item v-if="this.$cookies.get('token')" right href="/#/memberhome"><b-icon icon="person"></b-icon> Your Account</b-nav-item>
					<b-nav-item v-if="this.$cookies.get('token')" right href="/#/chats"><b-icon icon="envelope"></b-icon> Messages <b-badge style="margin-left: 5px;" variant="success" v-if="unread > 0">{{ unread }}</b-badge></b-nav-item>
					<b-nav-item v-if="this.$cookies.get('token')" right href="/#/forum"><b-icon icon="chat-left-quote"></b-icon> Connect</b-nav-item>
					
				</b-navbar-nav>
			</b-collapse>
		</b-navbar>
	</div>
</template>

<script>
export default {
	props: {
	menuMode: String
	},
	data() {
		return{
			chats: null,
			interval:null,
			unread: 0,
			admin: false
		}
	},
	methods: {
		getChats: function(){
			this.$http.get(this.$config.ApiUri + "/Customers/00000000-0000-0000-0000-000000000000/Chats")
				.then((result) => {
					this.chats = result.data;
					this.unread = 0;
					for(var x =0; x < this.chats.length; x++)
					{
						this.unread += this.chats[x].unread;
					}
				}
			);
		},
		menuEvent(mode) {
			this.menuMode = mode;
			this.$emit('menuEvent', mode);
		},
		onLoad() {
			this.interval = setInterval(() => this.getChats(), 10000);
			if (this.$route && this.$route.meta && this.$route.meta.admin) {
				this.admin = true;
			}
		},
		onUnLoad() {
			clearInterval(this.interval);
		}
	},
	mounted: function() { this.onLoad(); },
	destroyed:  function() { this.onUnLoad(); }
}
</script>

<style scoped>

	h1.title
	{
		margin-bottom: 0px;
		font-weight: lighter !important;
	}

	.navbar-brand{
		margin-left:auto; margin-right: auto; padding-right: 30px
	}

	@media all and (max-width: 575px)
	{
		.navbar-brand{
			padding-right: 0px
		}
	}

</style>