var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.guide)?_c('div',{staticClass:"color-block-content"},[_c('div',{staticClass:"centered mt-3"},[_c('h1',[_vm._v("Neighborhood Spotlight:"),_c('br'),_vm._v(_vm._s(_vm.guide.name))]),(_vm.guide.description)?_c('p',{staticStyle:{"padding":"30px","padding-top":"20px","padding-bottom":"20px"}},[_vm._v(_vm._s(_vm.guide.description))]):_vm._e(),(_vm.guide.restaurants.length > 0)?_c('div',{staticClass:"hero sub-hero mt-3",style:('background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(' + 
				(_vm.guide.restaurants[0].imageUri ? _vm.guide.restaurants[0].imageUri : require('@/assets/resturant.webp')) + ')')},[_vm._v(" Restaurants "),_c('p',_vm._l((_vm.guide.restaurants),function(p){return _c('span',{key:p.id},[_c('a',{attrs:{"href":p.url ? p.url : null,"target":"_blank"}},[_vm._v(_vm._s(p.name))]),_c('br')])}),0)]):_vm._e(),(_vm.guide.bars.length > 0)?_c('div',{staticClass:"hero sub-hero mt-3",style:('background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(' + 
				(_vm.guide.bars[0].imageUri ? _vm.guide.bars[0].imageUri : require('@/assets/pub.jpg')) + ')')},[_vm._v(" Bars "),_c('p',_vm._l((_vm.guide.bars),function(p){return _c('span',{key:p.id},[_c('a',{attrs:{"href":p.url ? p.url : null,"target":"_blank"}},[_vm._v(_vm._s(p.name))]),_c('br')])}),0)]):_vm._e(),(_vm.guide.pubs.length > 0)?_c('div',{staticClass:"hero sub-hero mt-3",style:('background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(' + 
				(_vm.guide.pubs[0].imageUri ? _vm.guide.pubs[0].imageUri : require('@/assets/pub.jpg')) + ')')},[_vm._v(" Pubs "),_c('p',_vm._l((_vm.guide.pubs),function(p){return _c('span',{key:p.id},[_c('a',{attrs:{"href":p.url ? p.url : null,"target":"_blank"}},[_vm._v(_vm._s(p.name))]),_c('br')])}),0)]):_vm._e(),(_vm.guide.coffeeShops.length > 0)?_c('div',{staticClass:"hero sub-hero mt-3",style:('background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(' + 
				(_vm.guide.coffeeShops[0].imageUri ? _vm.guide.coffeeShops[0].imageUri : require('@/assets/coffee.webp')) + ')')},[_vm._v(" Coffee "),_c('p',_vm._l((_vm.guide.coffeeShops),function(p){return _c('span',{key:p.id},[_c('a',{attrs:{"href":p.url ? p.url : null,"target":"_blank"}},[_vm._v(_vm._s(p.name))]),_c('br')])}),0)]):_vm._e(),(_vm.guide.placesOfInterest.length > 0)?_c('div',{staticClass:"hero sub-hero mt-3",style:('background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(' + 
				(_vm.guide.placesOfInterest[0].imageUri ? _vm.guide.placesOfInterest[0].imageUri : require('@/assets/coffee.webp')) + ')')},[_vm._v(" Places "),_c('p',_vm._l((_vm.guide.placesOfInterest),function(p){return _c('span',{key:p.id},[_c('a',{attrs:{"href":p.url ? p.url : null,"target":"_blank"}},[_vm._v(_vm._s(p.name))]),_c('br')])}),0)]):_vm._e(),(_vm.guide.primarySchools.length > 0)?_c('div',{staticClass:"hero sub-hero mt-3",style:('background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(' + 
				(_vm.guide.primarySchools[0].imageUri ? _vm.guide.primarySchools[0].imageUri : require('@/assets/primarySchool.png')) + ')')},[_vm._v(" Primary Schools "),_c('p',_vm._l((_vm.guide.primarySchools),function(p){return _c('span',{key:p.id},[_c('a',{attrs:{"href":p.url ? p.url : null,"target":"_blank"}},[_vm._v(_vm._s(p.name))]),_c('br')])}),0)]):_vm._e(),(_vm.guide.secondarySchools.length > 0)?_c('div',{staticClass:"hero sub-hero mt-3",style:('background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(' + 
				(_vm.guide.secondarySchools[0].imageUri ? _vm.guide.secondarySchools[0].imageUri : require('@/assets/secondarySchool.png')) + ')')},[_vm._v(" Secondary Schools "),_c('p',_vm._l((_vm.guide.secondarySchools),function(p){return _c('span',{key:p.id},[_c('a',{attrs:{"href":p.url ? p.url : null,"target":"_blank"}},[_vm._v(_vm._s(p.name))]),_c('br')])}),0)]):_vm._e(),(_vm.guide.fitness.length > 0)?_c('div',{staticClass:"hero sub-hero mt-3",style:('background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(' + 
				(_vm.guide.fitness[0].imageUri ? _vm.guide.fitness[0].imageUri : require('@/assets/fitness.png')) + ')')},[_vm._v(" Fitness "),_c('p',_vm._l((_vm.guide.fitness),function(p){return _c('span',{key:p.id},[_c('a',{attrs:{"href":p.url ? p.url : null,"target":"_blank"}},[_vm._v(_vm._s(p.name))]),_c('br')])}),0)]):_vm._e()])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }