var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filledBg",attrs:{"id":"fullWidthContent"}},[(_vm.members)?_c('div',{staticClass:"margined"},[_c('b-form-input',{staticClass:"mb-2",attrs:{"placeholder":"search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_vm._v(" "+_vm._s(_vm.members.length)+" member(s) "),_c('b-table',{attrs:{"striped":"","hover":"","filter":_vm.search,"items":_vm.members,"fields":[
			{key: 'approved', label: 'Approved'},
			{key: 'person.firstName', label: 'Name'},
			{key: 'person.lastName', label: 'Surname'},
			{key: 'person.email', label: 'Email'},
			{key: 'person.mobilePhone', label: 'Phone'},
			{key: 'person.regReason', label: 'Reason'},
			{key: 'person.city', label: 'City'},
			{key: 'person.insta', label: 'Insta'},
			{key: 'person.linkedIn', label: 'LinkedIn'},
			{key: 'createDateTime', label: 'Registered'} ],"selectable":"","select-mode":"single"},on:{"row-clicked":_vm.selectCustomer}})],1):_vm._e(),(_vm.member)?_c('b-modal',{attrs:{"id":"modalMessage","centered":"","title":"Approve Member","hide-header":true},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"size":"","variant":"primary"},on:{"click":_vm.approve}},[_vm._v(" OK ")])]},proxy:true}],null,false,3690752954)},[_c('h2',[_vm._v("Approve "+_vm._s(_vm.member.person.firstName)+"?")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }