<template>
<div id="fullWidthContent" class="filledBg" :style="'min-height:' + this.$parent.heightAfterHeaderFooter + 'px'">

	<div class="margined">
		<h2>Area Guides</h2>
		<ul>
		<li v-for="g in guides" :key="g.id">

				<b-button variant="link" :href="'#/areaguideadmin/'+ g.id" >{{ g.name }}</b-button>
				<!-- <ul>
					<li v-for="p in g.places" :key="p.id">{{ p.name }}</li>
				</ul> -->
			</li>
		</ul>
		<br/>
		<b-button @click="newArea">New</b-button>
	</div>

</div>
</template>
<script>
//import AreaGuide from './AreaGuide.vue'
export default {
  name: 'AreaGuidesAdmin',
  metaInfo() {
        return { 
            title: "Three Degrees Admin - Area Guides",
        }
    },
  components: {
	//AreaGuide
  },
  data() {
	return{
		guides: null		
	}
  },
  methods: {
	hideModal: function() {
		this.$bvModal.hide("modalMessage");
	},
	getAreaGuides: function(){
		this.$http.get(this.$config.ApiUri + "/AreaGuides")
			.then((result) => {
				this.guides = result.data;
			}
		)
	},
	newArea: function() {
		this.$router.push("areaGuideAdmin/-");
	},
	onLoad() {
		this.getAreaGuides();
	}
  },
  mounted: function() { this.onLoad(); }
}
</script>

<style scoped>



</style>