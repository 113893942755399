<template>
<div id="fullWidthContent" class="filledBg">
	
	<div class="margined">
		<div class="centered">
			<b-avatar :src="member.profilePicture" :text="member.person.firstName[0] + member.person.lastName[0]" size="5rem"></b-avatar><br/>
			<b-link @click="editPhotoMode=true"><small>change photo</small></b-link>
			<b-row class="mt-2 mb-3" v-if="editPhotoMode">
				<b-col>
					<p class="mb-0">Upload a new profile photo:</p>
					<Uploader class="mt-2" 
						:server="this.$config.ApiUri + '/Customers/00000000-0000-0000-0000-000000000000/Profile/Photos'"
						@change="changeMedia"
						@add="photoAdded"
						max-filesize="10"
						max=1
					/>
				</b-col>
			</b-row>
		</div>
		<b-form>
			<b-form-group id="input-group-1" label="Bio:" label-for="bio" description="">
				<b-form-textarea id="bio" v-model="member.person.bio" type="text" required></b-form-textarea>
			</b-form-group>
			<b-form-group v-if="false" id="input-group-1" label="Title:" label-for="title" description="">
				<b-form-input id="title" v-model="member.person.title" type="text" placeholder="" required></b-form-input>
			</b-form-group>
			<b-row cols="2">
				<b-col>
					<b-form-group id="input-group-1" label="First name:" label-for="firstName" description="">
						<b-form-input id="firstName" v-model="member.person.firstName" type="text" placeholder="" required></b-form-input>
					</b-form-group>
				</b-col>
				<b-col>
					<b-form-group id="input-group-1" label="Last name:" label-for="lastName" description="">
						<b-form-input id="lastName" v-model="member.person.lastName" type="text" placeholder="" required></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<b-form-group id="input-group-1" label="Mobile Number:" label-for="txtMobile" description="">
						<b-form-input id="txtMobile" v-model="member.person.mobilePhone" type="phone" placeholder="" required></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>
			<b-row cols="2">
				<b-col>
					<b-form-group id="input-group-1" label="LinkedIn:" label-for="linkedIn" description="">
						<b-form-input id="linkedIn" v-model="member.person.linkedIn" type="text" placeholder="" required></b-form-input>
					</b-form-group>
				</b-col>
				<b-col>
					<b-form-group id="input-group-1" label="Instagram:" label-for="instagram" description="">
						<b-form-input id="instagram" v-model="member.person.insta" type="text" placeholder="" required></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>
			<b-form-group id="input-group-1" label="Website:" label-for="website" description="">
				<b-form-input id="website" v-model="member.person.website" type="text" placeholder="" required></b-form-input>
			</b-form-group>
			<div class="margined centered more-margin-top more-margin-bottom">
				<b-button @click="saveMember" variant="outline-primary">Save</b-button>
			</div>
		</b-form>
	</div>
	
	

</div>
</template>
<script>
import Uploader from 'vue-media-upload'
export default {
  name: 'Profile',
  metaInfo() {
        return { 
            title: "Three Degrees - Profile",
        }
    },
  components: {
	Uploader
  },
  data() {
	return{
		member: null,
		editPhotoMode: false
	}
  },
  methods: {
	getMember: function(){
		this.$http.get(this.$config.ApiUri + "/Customers/00000000-0000-0000-0000-000000000000")
			.then((result) => {
				this.member = result.data;
				this.editPhotoMode = false;
			}
		)
	},
	saveMember: function(){
		this.$http.put(this.$config.ApiUri + "/Customers/00000000-0000-0000-0000-000000000000", this.member)
			.then((result) => {
				this.member = result.data;
				this.$router.push("memberhome");
			}
		)
	},
	photoAdded: function() {
		this.getMember();
	},
	onLoad() {
		this.getMember();
	}
  },
  mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

.badge-secondary {
    color: #fff;
    background-color: #b6b1a6;
}
	
</style>