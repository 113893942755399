<template>
<div id="fullWidthContent" class="filledBg" :style="'min-height:' + this.$parent.heightAfterHeaderFooter + 'px'">

	<div v-if="chats.length == 0" class="margined centered">
		<p class="margin-side">You do not have any chats.</p>
	</div>
	<div class="message" v-for="m in chats" :key="m.id" :class="(m.isInbound ? 'inbound-message' : 'outbound-message')">
		<a :href="'/#/Chats/' + m.withCustomer.id">
			<b-row>
				<b-col cols="3">
					<b-avatar :src="m.imageUri" :text="m.withCustomer.person.firstName[0] + m.withCustomer.person.lastName[0]" size="50px"></b-avatar><br/>
				</b-col>
				<b-col cols="8">
					<b-row>
						<b-col> <b-badge style="margin-right: 5px;" variant="success" v-if="m.unread > 0">{{ m.unread }}</b-badge> {{ m.withCustomer.person.firstName }} {{ m.withCustomer.person.lastName }}</b-col>
					</b-row>
					<b-row>
						<b-col class="lastmessage">{{ m.messages[m.messages.length -1].body }}</b-col>
					</b-row>
					<b-row>
						<b-col class="lastmessage-time">{{ m.lastMessageDateString }} </b-col>
					</b-row>
				</b-col>
			</b-row>
		</a>
	</div>

</div>
</template>
<script>
import Services from '../mixins/Services'
export default {
  name: 'Chats',
  mixins: [Services],
  metaInfo() {
        return { 
            title: "Three Degrees - Chats",
        }
    },
  components: {

  },
  data() {
	return{
		chats: null,
		interval:null
	}
  },
  methods: {
	
	getMember: function(){
		this._getSelf(null, () => { this.getChats(); })
	},
	
	getChats: function(){
		this.$http.get(this.$config.ApiUri + "/Customers/00000000-0000-0000-0000-000000000000/Chats")
			.then((result) => {
				this.chats = result.data;
			}
		).catch((e) => { if (e.response.status == 401) { this.$router.push('login'); } })
	},

	onLoad() {
		this.getMember();
		this.interval = setInterval(() => this.getChats(), 10000);
	},

	onUnLoad() {
		clearInterval(this.interval);
	}

  },

  mounted: function() { this.onLoad(); },
  destroyed:  function() { this.onUnLoad(); }

}
</script>

<style scoped>

#fullWidthContent {
	min-height: 90vh;
}

.badge-secondary {
    color: #fff;
    background-color: #b6b1a6;
}

.message
{
	margin-left: 30px;
	margin-right: 30px;
	margin-top: 10px;
	padding-bottom: 10px;
	padding-right: 10px;
	border-bottom: 1px solid #f1ecdf
}

.lastmessage
{
	color: #555555;
	white-space: nowrap;
	overflow: hidden;
}

.lastmessage-time
{
	text-align: right;
	font-size: 10px;
}
	
</style>