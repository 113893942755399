<!-- Public Landing -->
<template>
	<div id="fullWidthContent" :style="'padding-top: 0px; min-height:' + this.$parent.height + 'px;'">
		
		<div class="hero" :style="'background-image: url(' + require('@/assets/HomeHero.png') + '); height:' + this.$parent.height + 'px'">
			<div class="menu"><a href="#howitworks">How it Works</a><a href="#about">About</a><a href="/#/register">Apply Now</a></div>
			<h1>Three<br/>Degrees</h1>
			<p>A members network of beautiful homes to rent and swap</p>
			<b-button class="mt-3" href="/#/register">Apply Now</b-button>
		</div>

		<div class="block" id="howitworks" :style="'h--eight:' + this.$parent.height + 'px'">
			<h1 style="padding-left: 30px;">How it Works</h1>
			<b-row>
				<b-col md="4" sm="12" class="mt-2">
					<b-row>
						<b-col><img fluid :src="require('@/assets/hiw1.jpg')" /></b-col>
					</b-row>
					<b-row>
						<b-col><h2>Apply to Unlock Access</h2></b-col>
					</b-row>
					<b-row>
						<b-col>Join the community by answering a few questions about yourself, and enter your invite code if you have one. Once you are approved, you will receive an email to activate your membership.</b-col>
					</b-row>
				</b-col>
				<b-col md="4" sm="12" class="mt-2">
					<b-row>
						<b-col><img fluid :src="require('@/assets/hiw2.jpg')" /></b-col>
					</b-row>
					<b-row>
						<b-col><h2>Activate & Sync Network</h2></b-col>
					</b-row>
					<b-row>
						<b-col>We know some people prefer to rent within their existing or extended networks. Download our app and sync your contacts to view mutual connections to other members when browsing homes.</b-col>
					</b-row>
				</b-col>
				<b-col md="4" sm="12" class="mt-2">
					<b-row>
						<b-col><img fluid :src="require('@/assets/hiw4.jpg')" /></b-col>
					</b-row>
					<b-row>
						<b-col><h2>List Your Home</h2></b-col>
					</b-row>
					<b-row>
						<b-col>Rented or owned, small or large – we believe beauty comes in all shapes and sizes and welcome all well-cared for homes. Our platform caters to short and long-term rentals, as well as house swaps.</b-col>
					</b-row>
				</b-col>
			</b-row>
		</div>

		<div id="about" class="block block-alt centered-block" :style="'h--eight:' + this.$parent.height + 'px'">
			<h1>About Us</h1>
			<div class="small-block">
				<p>Three Degrees is a members network of exceptional homes to rent & swap around the world. 
					We think everyone should be able to rent from a network they can trust, without sacrificing on style. Which is why we vet everyone who applies to our community, so you don't have to. 
					Our platform connects members with friends and friends-of-friends to help you find beautiful homes to stay in, whether its for a week, a month or a year. We offer short and long term rentals, and house swaps - the choice is yours. From studio apartments to five bedroom townhouses, all we look for is homes that are loved and well-cared for. 
					So, whether you are looking for a home, have a home to rent or swap, or just curious to take a look in to our world, apply today. 
					Renters & owners welcome.
				</p>

				<b-row class="mt-4 centered">
					<b-col md="6" sm="12">
						<div class="dl mt-3 mb-4">
							<h2>iOS</h2>
							<p class="small centered">Mobile</p>
							<a href="https://apps.apple.com/uk/app/three-degrees/id6467742434"><b-img height="60" :src="require('../assets/ios.svg')" /></a>
						</div>
					</b-col>
					<b-col md="6" sm="12">
						<div class="dl mt-3">
							<h2>Android</h2>
							<p class="small centered">Mobile</p>
							<a href="https://s3.eu-west-2.amazonaws.com/www.threedegrees.world/app-release.apk"><b-img height="60" :src="require('../assets/android-dd.png')" /></a>
						</div>
					</b-col>
				</b-row>
			</div>
		</div>

	</div>	
</template>

<script>

export default {
	name: "Welcome",
	metaInfo() {
        return { 
            title: "Three Degrees - Welcome",
        }
    },
	data() {
		return {
			
		};
	},	
	methods: {
		
		onLoad: function () {
			
		},

		openApp: function() {
			window.open();
		}

	},
	mounted: function () {
		this.onLoad();
	}
};
</script>

<style scoped>

.menu a {
	margin-right: 20px;
	margin-left: 20px;
	text-decoration: underline !important;
}

@media all and (max-width: 700px)
{
	.menu a {
		margin-right: 7px;
		margin-left: 7px;
	}
}

.hero
{
	background-size:cover;
	background-position: center;
	padding-top: 20px;
	color: white;
	text-align: center;
	padding-left: 30px;
	padding-right: 30px;
}


.hero h1 {
	font-size: 180px !important;
	color: white;
	line-height: 0.7;
	text-transform:uppercase;
	margin-top: 80px;
}

@media all and (max-width: 700px)
{
	.hero h1 {
		font-size: 150px !important;
	}
}

.hero p {
	font-size: 20px;
}

.block h2 {
	margin-top: 10px;
}

.block img {
	max-width: 100%;
}

.block .col
{
	padding-left: 30px;
	padding-right: 30px;
}

.block
{
	background-color: #D7D1C4;
	padding: 40px;
	padding-bottom: 60px;
}

.block-alt
{
	background-color: #d2c3b1;
}

.small-block {
	max-width: 60%;
	margin-right: auto;
	margin-left: auto;
}


@media all and (max-width: 700px)
{
	.small-block {
		max-width: unset;
	}
}

.centered-block {
	text-align: center;
	padding: 80px;
}

.centered-block p {
	text-align: justify;
}

.btn {
	color: white;
	background-color: #D7D1C4;
	border-color: white;
	border-width: 2px;
	border-radius: 30px;
	padding: 10px 20px 10px 20px;
}

@media all and (max-width: 600px)
{
	.desktopButton {
		display:none;
	}
}

</style>
