<template>
	<div id="fullWidthContent" :style="'min-height:' + this.$parent.heightAfterHeaderFooter + 'px'">
		<!-- <b-img v-if="!done && !enterCode" class="hero" :src="require('@/assets/Image2.png')" /> -->
		<b-form class="margined">
			<h2>You have logged out</h2>
		</b-form>
	</div>
</template>

<script>
export default {
	name: 'Logout',
	data() {
	return{
		
	}
	},
	methods: {
		logout: function() {
			this.$cookies.set("token", null);
			window.location = "/#/home";
		}
	},
	mounted: function() {
		this.logout();
	}
}
</script>


<style>

</style>