<template>
<div id="fullWidthContent" class="filledBg">

	<b-button class="backNav" @click="$router.back()" size="xs" variant="link"><b-icon-caret-left-fill /></b-button>

	<div class="margined">
		<h2>{{ guide.name }}</h2>
		<b-form>
			
			<b-form-group label="Post Code:">
				<b-form-input v-model="guide.postCode" type="text" required placeholder="E.g SW15"></b-form-input>
			</b-form-group>
		
			<b-form-group label="Name:">
				<b-form-input v-model="guide.name" type="text" required></b-form-input>
			</b-form-group>

			<b-form-group label="Description:">
				<b-form-textarea rows="4" v-model="guide.description" type="text" required></b-form-textarea>
			</b-form-group>

			<h2 class="mt-4">Places</h2>
			<b-col v-for="p in guide.places" :key="p.id">
				<h3 class="mt-4">{{ p.name }}</h3>
				<b-form-group label="Type:">
					<b-form-select v-model="p.placeType" :options="placeTypes" required></b-form-select>
				</b-form-group>
				<b-form-group label="Name:">
					<b-form-input v-model="p.name" type="text" required></b-form-input>
				</b-form-group>
				<b-form-group label="Image:">
					<b-form-input v-model="p.imageUri" type="text" required></b-form-input>
				</b-form-group>
				<b-form-group label="Best For:">
					<b-form-input v-model="p.bestFor" type="text" required></b-form-input>
				</b-form-group>
				<b-form-group label="Url:">
					<b-form-input v-model="p.url" type="text" required></b-form-input>
				</b-form-group>
				<b-row>
					<b-col>
						<b-form-group label="Post Code:">
							<b-form-input v-model="p.postCode" @change="postCodeLookup(p)" type="text" required></b-form-input>
						</b-form-group>
					</b-col>
					<b-col>
						<b-form-group label="Lat:">
							<b-form-input v-model="p.lat" type="text" required></b-form-input>
						</b-form-group>
					</b-col>
					<b-col>
						<b-form-group label="Lng:">
							<b-form-input v-model="p.lng" type="text" required></b-form-input>
						</b-form-group>
					</b-col>

				</b-row>
				<div class="map">
					
					<GmapMap :options="{
						zoomControl:true,  
						mapTypeControl: false,
						scaleControl: true,
						streetViewControl: true,
						rotateControl: false,
						fullscreenControl: true,
						disableDefaultUi: false
					}" :center="{ lat:parseFloat(p.lat), lng:parseFloat(p.lng) }" :zoom="15" style="width: 100%; height: 250px" 
					>
					<GmapMarker
					:key="index"
					:position="{ lat:parseFloat(p.lat), lng:parseFloat(p.lng) }"
					:draggable=true
					@dragstart="selectedPlace = p;"
					@dragend="markerDragged"
					/>
				</GmapMap>
			</div>
			<b-button variant="danger" size="sm" @click="deletePlace(p.id)">Delete {{ p.name }}</b-button>

			</b-col>
			<p class="mt-5">
				<b-button variant="success" @click="addPlace">Add Place to {{ guide.postCode }}</b-button>
			</p>
				
		</b-form>
		<br/>
		<b-alert :show="saved" variant="success">{{ message }}</b-alert>
		<b-button @click="save">Save</b-button> <b-button v-if="guide.id" @click="deleteArea" variant="danger">Delete</b-button>

		<div class="mt-5" v-if="this.guide && this.guide.id != '-'">
			<h3>Preview</h3>
			<AreaGuide :PostCode="guide.postCode"></AreaGuide>
		</div>
	</div>

	<b-modal id="modalMessage" centered title="Create Area Guide" hide-header="true">
		<h2>Please fix the following:</h2>
		<p class="my-4" v-html="message"></p>
		<template #modal-footer>
			<!-- Emulate built in modal footer ok and cancel button actions -->
			<b-button size="" variant="primary" @click="hideModal">
				OK
			</b-button>
		</template>
	</b-modal>

</div>
</template>
<script>
import AreaGuide from './AreaGuide.vue'
export default {
  name: 'AreaGuideAdmin',
  metaInfo() {
        return { 
            title: "Three Degrees Admin - Area Guide",
        }
    },
  components: {
	AreaGuide
  },
  data() {
	return{
		guide: null,
		selectedPlace:null,
		message: null,
		saved: false,
		savedCountDown: 5,
		savedMaxCountdown: 5,
		placeTypes: [
			{ value: 0, text: "Place Type" },
			{ value: 1, text: "Bar" },
			{ value: 2, text: "Pub" },
			{ value: 3, text: "Coffee Shop" },
			{ value: 4, text: "Restaurant" },
			{ value: 5, text: "Shop" },
			{ value: 6, text: "Park" },
			{ value: 7, text: "Place" },
			{ value: 8, text: "Primary School" },
			{ value: 9, text: "Secondary Schools" },
			{ value: 10, text: "Fitness" },
			{ value: 11, text: "Other" }
		],
	}
  },
  methods: {
	hideModal: function() {
		this.$bvModal.hide("modalMessage");
	},
	markerDragged: function(location) {
		this.selectedPlace.lat = location.latLng.lat();
		this.selectedPlace.lng = location.latLng.lng();
	},
	postCodeLookup: function(p){
		//alert(p.postCode);
		if (p.postCode.length >= 5)
		{
			
			var lookup = { postCode: p.postCode };
			this.$http.post(this.$config.ApiUri + "/Addresses/AddressLookupRequests", lookup)
				.then((result) => {
					p.postCode = result.data.addresses[0].postCode;
					//this.listing.property.address.town = result.data.addresses[0].town;
					p.lat = result.data.addresses[0].lat;
					p.lng = result.data.addresses[0].lng;
				}
			)
		}
	},
	showSaved: function() {
		this.message = "Saved!"
		this.saved = true;
		this.interval = setInterval(() => this.saved = false, 4000);
	},
	getAreaGuide: function(){
		this.$http.get(this.$config.ApiUri + "/AreaGuides/" + this.$route.params.id)
			.then((result) => {
				this.guide = result.data;
				for (var x = 0; x < this.guide.places.length; x++) {
					if (this.guide.places[x].lat == null) {
						this.guide.places[x].lat = 0;
						this.guide.places[x].lng = 0;
					}
				}
			}
		)
	},
	showError: function(error) {
		this.message = "Sorry, something has gone wrong.  " +
			"Please screenshot this error and send it to the ThreeDegrees team: " + 
			JSON.stringify(error.response.data.errors); 
		this.$bvModal.show("modalMessage"); 
		this.saving = false;
	},
	save: function() {
		this.$http.post(this.$config.ApiUri + "/AreaGuides/", this.guide)
			.then((result) => {
				this.guide = result.data;
				this.showSaved();
			}
		).catch((error) => { this.showError(error); })
	},
	deleteArea: function() {
		this.$http.delete(this.$config.ApiUri + "/AreaGuides/" + this.guide.id)
			.then(() => {
				this.$router.back();
			}
		)
	},
	deletePlace: function(id) {
		if (id == null) {
			for(var x = 0; x < this.guide.places.length; x++) {
				if (this.guide.places[x].id == id) {
					this.guide.places.pop(this.guide.places[x]);
					break;
				}
			}	
		} else {
			this.$http.delete(this.$config.ApiUri + "/AreaGuides/Places/" + id)
				.then(() => {
					this.onLoad();
				}
			)
		}
	},
	addPlace: function(){
		if (!this.guide.places) {
			this.guide.places = [];
		}
		this.guide.places.push({"name": "", "bestFor": "", "imageUri": "", "url": ""});
	},
	onLoad() {
		if (this.$route.params.id != '-') {
			this.getAreaGuide();
		}
		else {
			this.guide = {"postCode": "", "name": ""};
		}
	}
  },
  mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

.btn-link
{
	color: #000000 !important;
}

</style>