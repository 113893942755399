<template>
	<div class="color-block-content" v-if="guide">
		<div class="centered mt-3">
			<h1>Neighborhood Spotlight:<br/>{{ guide.name }}</h1>
			<p v-if="guide.description" style="padding: 30px; padding-top:20px; padding-bottom: 20px;">{{ guide.description }}</p>
			<div v-if="guide.restaurants.length > 0" class="hero sub-hero mt-3" 
					:style="'background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(' + 
					(guide.restaurants[0].imageUri ? guide.restaurants[0].imageUri : require('@/assets/resturant.webp')) + ')'">
				Restaurants
				<p>
					<span v-for="p in guide.restaurants" :key="p.id"><a :href="p.url ? p.url : null" target="_blank">{{ p.name }}</a><br/></span>
				</p>
			</div>
			<div v-if="guide.bars.length > 0" class="hero sub-hero mt-3" 
					:style="'background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(' + 
					(guide.bars[0].imageUri ? guide.bars[0].imageUri : require('@/assets/pub.jpg')) + ')'">
				Bars
				<p>
					<span v-for="p in guide.bars" :key="p.id"><a :href="p.url ? p.url : null" target="_blank">{{ p.name }}</a><br/></span>
				</p>
			</div>
			<div v-if="guide.pubs.length > 0" class="hero sub-hero mt-3" 
					:style="'background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(' + 
					(guide.pubs[0].imageUri ? guide.pubs[0].imageUri : require('@/assets/pub.jpg')) + ')'">
				Pubs
				<p>
					<span v-for="p in guide.pubs" :key="p.id"><a :href="p.url ? p.url : null" target="_blank">{{ p.name }}</a><br/></span>
				</p>
			</div>
			<div v-if="guide.coffeeShops.length > 0" class="hero sub-hero mt-3" 
					:style="'background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(' + 
					(guide.coffeeShops[0].imageUri ? guide.coffeeShops[0].imageUri : require('@/assets/coffee.webp')) + ')'">
				Coffee
				<p>
					<span v-for="p in guide.coffeeShops" :key="p.id"><a :href="p.url ? p.url : null" target="_blank">{{ p.name }}</a><br/></span>
				</p>
			</div>
			<div v-if="guide.placesOfInterest.length > 0" class="hero sub-hero mt-3" 
					:style="'background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(' + 
					(guide.placesOfInterest[0].imageUri ? guide.placesOfInterest[0].imageUri : require('@/assets/coffee.webp')) + ')'">
				Places
				<p>
					<span v-for="p in guide.placesOfInterest" :key="p.id"><a :href="p.url ? p.url : null" target="_blank">{{ p.name }}</a><br/></span>
				</p>
			</div>
			<div v-if="guide.primarySchools.length > 0" class="hero sub-hero mt-3" 
					:style="'background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(' + 
					(guide.primarySchools[0].imageUri ? guide.primarySchools[0].imageUri : require('@/assets/primarySchool.png')) + ')'">
				Primary Schools
				<p>
					<span v-for="p in guide.primarySchools" :key="p.id"><a :href="p.url ? p.url : null" target="_blank">{{ p.name }}</a><br/></span>
				</p>
			</div>
			<div v-if="guide.secondarySchools.length > 0" class="hero sub-hero mt-3" 
					:style="'background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(' + 
					(guide.secondarySchools[0].imageUri ? guide.secondarySchools[0].imageUri : require('@/assets/secondarySchool.png')) + ')'">
				Secondary Schools
				<p>
					<span v-for="p in guide.secondarySchools" :key="p.id"><a :href="p.url ? p.url : null" target="_blank">{{ p.name }}</a><br/></span>
				</p>
			</div>
			<div v-if="guide.fitness.length > 0" class="hero sub-hero mt-3" 
					:style="'background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(' + 
					(guide.fitness[0].imageUri ? guide.fitness[0].imageUri : require('@/assets/fitness.png')) + ')'">
				Fitness
				<p>
					<span v-for="p in guide.fitness" :key="p.id"><a :href="p.url ? p.url : null" target="_blank">{{ p.name }}</a><br/></span>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ['PostCode'],
	data() {
		return{
			guide: null
		}
	},
	methods: {
		getGuide: function(postCode){
			this.$http.get(this.$config.ApiUri + "/AreaGuides/" + postCode)
				.then((result) => {
					this.guide = result.data;
					this.guide.bars = [];
					this.guide.pubs = [];
					this.guide.coffeeShops = [];
					this.guide.restaurants = [];
					this.guide.placesOfInterest = [];
					this.guide.primarySchools = [];
					this.guide.secondarySchools = [];
					this.guide.fitness = [];
					for(var x = 0; x < this.guide.places.length; x++){
						// temp disable links
							this.guide.places[x].url = null;
						//
						if (this.guide.places[x].placeType == 1) { this.guide.bars.push(this.guide.places[x]); }
						if (this.guide.places[x].placeType == 2) { this.guide.pubs.push(this.guide.places[x]); }
						if (this.guide.places[x].placeType == 3) { this.guide.coffeeShops.push(this.guide.places[x]); }
						if (this.guide.places[x].placeType == 4) { this.guide.restaurants.push(this.guide.places[x]); }
						if (this.guide.places[x].placeType == 7) { this.guide.placesOfInterest.push(this.guide.places[x]); }
						if (this.guide.places[x].placeType == 8) { this.guide.primarySchools.push(this.guide.places[x]); }
						if (this.guide.places[x].placeType == 9) { this.guide.secondarySchools.push(this.guide.places[x]); }
						if (this.guide.places[x].placeType == 10) { this.guide.fitness.push(this.guide.places[x]); }
					}
				}
			)
		},
		onLoad() {
			this.getGuide(this.PostCode);
		}
	},
	mounted: function() { this.onLoad(); }
}

</script>

<style scoped>

.hero
{
	color: white;
	height: 500px;
	background-position: center;
	background-size: cover;
	font-family: GrifintolL, 'Gloock', serif !important;
	font-size: 75px !important;
	text-align: center;
	padding: 70px;
	padding-top: 100px;
	text-transform: capitalize;
	line-height: 1.5;
}
.sub-hero
{
	color: white;
	height: 300px;
	background-position: center;
	background-size: cover;
	font-family: GrifintolL, 'Gloock', serif !important;
	font-size:65px !important;
	text-align: center;
	padding: 20px;
	padding-top: 50px;
	text-transform: capitalize;
	line-height: 1.5;
}

.sub-hero p {
	font-size: 27px;
}

.color-block-content
{
	background-color: rgb(142, 164, 135);
}
.color-block-content h1{
	margin: 10px;
	padding-top: 20px !important;
}

</style>