<template>
<div id="fullWidthContent" class="filledBg" :style="'min-height:' + this.$parent.heightAfterHeader + 'px'">

	<!-- <div class="searchBar">
		<b-input-group class="mt-3">
			<b-form-input v-model="searchQuery" id="search" placeholder="quick search"></b-form-input>
			<b-input-group-append>
				<b-button @click="search" variant="outline-success"><b-icon-search	/></b-button>
			</b-input-group-append>
		</b-input-group>
	</div> -->


	<a :href="'/#/listings/' + l.id" v-for="l in listings" :key="l.id">
		<ListingResult Lite="true" :Listing="l"/>
	</a>
</div>
</template>
<script>
import ListingResult from './ListingResult.vue'

export default {
  name: 'Listings',
  components: {
	ListingResult
  },
  data() {
	return{
		listings: null,
		searchQuery: ""
	}
  },
  methods: {
	search: function(){
		this.$http.get(this.$config.ApiUri + "/Listings/" + this.searchQuery)
			.then((result) => {
				this.listings = result.data;
			}
		)
	},
	onLoad() {
		//this.$config.ApiUri = "https://localhost:5001";
		this.search()
	}
  },
  mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

	.searchBar
	{
		background-color: #b6b1a6 !important;
		padding: 10px;
		padding-top: 1px;
		padding-bottom: 13px;
	}

	

</style>