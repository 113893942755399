<template>
<div id="fullWidthContent" class="filledBg">

	<b-button class="backNav" @click="$router.back()" size="xs" variant="link"><b-icon-caret-left-fill /></b-button>
	<Collection v-if="collection" :Collection="collection" :Details="true" />
	
</div>
</template>
<script>

import Collection from './Collection.vue'


export default {
  name: 'CollectionPage',
  metaInfo() {
        return { 
            title: "Three Degrees - Collection",
        }
    },
  components: {
	Collection
  },
  data() {
	return{
		collection: null,
	}
  },
  methods: {
	getCollection: function(){
		this.$http.get(this.$config.ApiUri + "/Collections/" + this.$route.params.id)
			.then((result) => {
				this.collection = result.data;
			}
		)
	},
	onLoad() {
		this.getCollection();
	}
  },
  mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

.btn-link
{
	color: #000000 !important;
}
</style>