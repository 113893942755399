<template>
<div id="fullWidthContent" class="filledBg" :style="'min-height:' + this.$parent.heightAfterHeaderFooter + 'px'">

	<Search :Lite=true @onSearchResults="onSearchResults"/>	

	<div v-if="initial">
		<h2 class="margined centered">We are on a mission to build the world's best community of homeowners and renters.</h2>
		<div class="margined centered margin-top margin-bottom">
			<b-button @click="apply" class="no-width-set" variant="outline-primary">Apply</b-button>
		</div>
	</div>

	<div class="listings-wrapper mt-5">
		<a :href="'/#/listings/' + l.id" v-for="l in listings" :key="l.id">
			<ListingResult v-if="l.property.address.town" :Listing="l" Lite="true" :ShowAvailability=true Member="member" :Favourite="isFavourite(l)" />
		</a>
	</div>
	<div style="clear:both" />

	<div v-if="initial" class="less-margined">
		<h3 class="mb-3">
			Three Degrees is a private network of design-led homes, rooms and spaces to rent in cities around the world. Designed to solve modern living, we are on a mission to build the world's best community of homeowners and renters.
		</h3>
		<p>
			We think everyone should be able to rent from a network they can trust, without sacrificing on style. Which is why we vet everyone who applies to our community, so you don't have to. It's renting, just done better.
		</p>
		<p>So, whether you are looking for a home, have a room or room to rent, or just curious to take a look in to our world, join the waitlist ahead of our launch:</p>
		<div class="margined centered more-margin-top margin-bottom">
			<b-button @click="apply" class="no-width-set" variant="outline-primary">Apply</b-button>
		</div>
	</div>

</div>
</template>
<script>
import Search from './Search.vue'
import ListingResult from './ListingResult.vue'

export default {
  name: 'SearchPage',
  metaInfo() {
        return { 
            title: "Three Degrees - Search",
        }
    },
  components: {
	ListingResult,
	Search
  },
  data() {
	return{
		listings: null,
		member: null,
		initial: false,
	}
  },
  methods: {
	apply: function() {
		this.$router.push("/");
	},
	onSearchResults(results) {
		this.listings = results;
		this.initial = false;
	},
	getMember: function(){
		this.$http.get(this.$config.ApiUri + "/Customers/00000000-0000-0000-0000-000000000000")
			.then((result) => {
				this.member = result.data;
			}
		)
	},
	getMemberSavedListings: function(){
		this.$http.get(this.$config.ApiUri + "/Customers/00000000-0000-0000-0000-000000000000/SavedListings")
			.then((result) => {
				this.savedListings = result.data;
			}
		)
	},
	isFavourite: function(lis){
		if (this.savedListings) {
			for (var x = 0; x < this.savedListings.length; x++) {
				if (this.savedListings[x].id == lis.id) {
					return true;
				}
			}
		}
		return false;
	},
	getListings: function(){
		this.$http.get(this.$config.ApiUri + "/Listings?limit=20")
			.then((result) => {
				this.listings = result.data;
			}
		)
	},
	addListing: function(){
		this.$router.push("/createlisting");
	},
	onLoad() {
		this.getMember();
		this.getMemberSavedListings();
		this.getListings();
	}
  },
  mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

#fullWidthContent {
	min-height: 90vh;
}

.badge-secondary {
    color: #fff;
    background-color: #b6b1a6;
}
	
</style>