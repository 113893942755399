<template>
<div class="fullWidthContent">
	<b-button class="backNav" @click="$router.back()" size="xs" variant="link"><b-icon-caret-left-fill /></b-button>
	<div class="listingResult" v-if="listing" >
		<b-carousel
			id="carousel-1"
			:interval="0"
			:indicators="true"
			controls
			background="#ffffff"
			img-width="1024"
			img-height="480"
		>
			<b-carousel-slide v-for="p in listing.property.photos" :key="p.uri" :img-src="p.uri"></b-carousel-slide>
		</b-carousel>

		<div class="colorBlock">
			<div class="title">{{listing.property.address.oneLinerV2}}</div>
			<div>{{ listing.property.bedrooms }} Bedrooms &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{ listing.property.bathrooms }} Bathrooms</div>
			<div>{{ listing.property.locationFeature }}</div>
			<div>{{ listing.pricing.priceString }}</div>
			<b-button v-if="self && !saved" variant="link" class="favourite mt-4" @click="saveFavourite"><b-icon-suit-heart /></b-button>
			<b-button v-if="self && saved" variant="link" class="favourite mt-4" @click="unSaveFavourite"><b-icon-suit-heart-fill /></b-button>
		</div>
		<div v-if="self && listing" class="enquireBlock" style="background-color: gainsboro;">
			<div >
				<b-button v-if="self.id == listing.customerId || admin" :href="'/#/editlisting/' + listing.id" class="mt-3 no-width-set" variant="link">Edit Listing</b-button>
				<b-button v-if="self.id != listing.customerId" @click="showMessageDialogue" class="mt-3 no-width-set" variant="link">Contact the Owner</b-button>
			</div>
		</div>

		<div class="colorBlock" v-if="!member">
			<p>Login or create an accout to view more property detail and contact the owner!</p>
			<b-row style="padding-left: 20px">
				<b-col>
					<b-button size="sm" variant="outline-secondary" :href="'/#/login?route=listings/' + listing.id"><b-icon-person/> Login</b-button>
				</b-col>
				<b-col>
					<b-button size="sm" variant="outline-secondary" href="/#/"><b-icon-person-plus/> Apply</b-button>
				</b-col>
			</b-row>
		</div>

		<div class="info" v-if="member">

			<h4>A note about the home</h4>
			<p v-html="listing.property.description.split('\n').join('<br>')"></p>

			<h4>What you need to know</h4>
			<ul>
				<li v-if="listing.forRent">Rental</li>
				<li v-if="listing.forSwap">House Swap</li>
				<li v-if="listing.availability">{{ listing.availability.availabilityString }}</li>
				<li v-if="listing.availability && listing.availability.notes && listing.availability.notes != ''">{{ listing.availability.notes }}</li>
				<li v-if="listing.property.furnishingType == 1">Furnished</li>
				<li v-if="listing.property.furnishingType == 2">Part Furnished</li>
				<li v-if="listing.property.furnishingType == 3">Unfurnished</li>
				<li v-if="listing.pricing.billsIncuded">Bills Included</li>
				<li v-if="!listing.pricing.billsIncuded">Bills Not Included</li>
				<li v-if="listing.property.bathroomsDescription != ''">{{ listing.property.bathroomsDescription }}</li>
				<li v-if="listing.property.bedroomsDescription != ''">{{ listing.property.bedroomsDescription }}</li>
			</ul>

			<h4>Property features:</h4>
			<ul>
				<li v-for="f in listing.property.features" :key="f">{{ f }}</li>
			</ul>

			<h4>Location</h4>
			<p>{{ listing.property.locationFeature }}</p>

		</div>

		<div class="map">
			<GmapMap :options="{
					zoomControl:false,  
					mapTypeControl: false,
					scaleControl: false,
					streetViewControl: true,
					rotateControl: false,
					fullscreenControl: true,
					disableDefaultUi: false
				}" :center="{ lat:listing.property.address.lat, lng:listing.property.address.lng }" :zoom="15" style="width: 100%; height: 250px" 
			>
				<!--GmapMarker
					:key="index"
					:position="{ lat:listing.property.address.lat, lng:listing.property.address.lng }"
				/-->
				<GmapCircle :options="{ center: {lat:listing.property.address.lat, lng:listing.property.address.lng}, radius:100, strokeWeight: 1, fillColor:'#D7D1C4' }" />
			</GmapMap>
		</div>

		<div class="info" v-if="member">

			<h4>About the owner, <a :href="'/#/members/' + member.id">{{ member.person.firstName }}</a></h4>
			<p>{{ member.person.bio }}</p>
			<p><b-button variant="outline-success" icon="person" size="sm" @click="navigateToOwner"><b-icon-person />View Profile</b-button></p>
		
		</div>

		<AreaGuide :PostCode="listing.property.address.areaGuideLookupKey" />
		
	</div>

	<b-modal id="modalMessage" centered title="Create Listing" :hide-header="true">
		<h2>Please fix the following:</h2>
		<p class="my-4" v-html="message"></p>
		<template #modal-footer>
			<!-- Emulate built in modal footer ok and cancel button actions -->
			<b-button size="" variant="primary" @click="hideModal">
				OK
			</b-button>
		</template>
	</b-modal>

	<b-modal id="modalEnquire" centered title="Contact the Owner" :hide-header="true" v-if="member">
		<h3 class="mb-3">You are messaging {{ member.person.firstName }}</h3>
		<b-form-input placeholder="Subject" v-model="enquiry.subject"></b-form-input><br/>
		<b-form-textarea rows="6" placeholder="Your Message" v-model="enquiry.body"></b-form-textarea>
		<b-row class="mt-3">
			<b-col>
				<b-form-checkbox v-model="enquiry.booking">Request a Booking</b-form-checkbox>
			</b-col>
		</b-row>
		<b-row v-if="listing.forSwap">
			<b-col>
				<b-form-checkbox v-model="enquiry.houseSwap">Request a House Swap</b-form-checkbox>
			</b-col>
		</b-row>
		<b-row v-if="enquiry.booking">
			<b-col>
				<b-form-group id="input-group-1" label="Check In:" label-for="startDate">
					<b-form-input id="startDate" v-model="enquiry.checkIn" type="date" placeholder=""></b-form-input>
				</b-form-group>
			</b-col>
			<b-col>
				<b-form-group id="input-group-1" label="Optional, Check Out:" label-for="endDate">
					<b-form-input id="endDate" v-model="enquiry.checkOut" type="date" placeholder="optional"></b-form-input>
				</b-form-group>
			</b-col>
		</b-row>
		<template #modal-footer>
			<!-- Emulate built in modal footer ok and cancel button actions -->
			<b-button size="" variant="outline-secondary" @click="hideEnquiryForm">
				Cancel
			</b-button>

			<b-button size="" variant="outline-secondary" @click="sendEnquiry">
				OK
			</b-button>
		</template>
	</b-modal>
	
</div>
</template>
<script>
import AreaGuide from './AreaGuide.vue'
export default {
  name: 'Listing',
  metaInfo() {
        return { 
            title: "Three Degrees - View Listing",
        }
    },
  components: {
	AreaGuide
  },
  data() {
	return{
		listing: null,
		savedListings: null,
		member: null,
		message: "",
		self: null,
		saved: false,
		furnishing: ["NotSet", "Fully", "Part", "No"],
		enquiry: {
			booking: false,
			checkIn:null,
			checkOut:null,
			houseSwap: false,
			listingId: "",
			subject: "",
			body: ""
		},
		admin: false
	}
  },
  methods: {
	navigateToOwner: function() {
		this.$router.push('/members/' + this.member.id);
	},
	saveFavourite: function(){
		var fave = { listingId:this.listing.id };
		this.$http.post(this.$config.ApiUri + "/Customers/00000000-0000-0000-0000-000000000000/SavedListings", fave)
			.then(() => {
				this.getSavedListings();
			}
		)
	},
	unSaveFavourite: function(){
		this.$http.delete(this.$config.ApiUri + "/Customers/00000000-0000-0000-0000-000000000000/SavedListings/" + this.listing.id)
			.then(() => {
				this.getSavedListings();
			}
		)
	},
	getListing: function(){
		this.$http.get(this.$config.ApiUri + "/Listings/" + this.$route.params.id)
			.then((result) => {
				this.listing = result.data;
				this.saved = false;
				this.getSavedListings();
				if (this.$cookies.get('token'))
				{
					this.$http.get(this.$config.ApiUri + "/Customers/" + this.listing.customerId)
						.then((result) => {
							this.member = result.data;
						}
					)
				}
			}
		)
	},
	getSavedListings: function(){
		this.$http.get(this.$config.ApiUri + "/Customers/00000000-0000-0000-0000-000000000000/SavedListings")
			.then((result) => {
				this.savedListings = result.data;
				this.isSaved();
			}
		)
	},
	isSaved: function() {
		if (this.listing && this.savedListings)
		{
			this.saved = false;
			for(var x = 0; x < this.savedListings.length; x++) {
				if (this.savedListings[x].id == this.listing.id) {
					this.saved = true;
				}
			}
		}
	},
	getSelf: function(){
		this.$http.get(this.$config.ApiUri + "/Customers/00000000-0000-0000-0000-000000000000")
			.then((result) => {
				this.self = result.data;
			}
		);
		if (this.$route && this.$route.meta && this.$route.meta.admin) {
			this.admin = true;
		}
	},
	showMessageDialogue() {
		//this.enquiry.subject = this.listing.property.title;
		//this.enquiry.body = "Hi, my name is " + this.self.person.firstName + " and I'd like some more information about your property:\r\n\r\n<Your message here>";
		this.$bvModal.show("modalEnquire");
	},
	hideEnquiryForm() {
		this.$bvModal.hide("modalEnquire");
	},
	hideModal() {
		this.$bvModal.hide("modalMessage");
	},
	sendEnquiry() {
		this.message = "";
		if (!this.enquiry.body || this.enquiry.body == "") { this.message += "<br/>Please enter a message";  }
		if (!this.enquiry.subject || this.enquiry.subject == "") { this.message += "<br/>Please enter a subject";  }
		if (this.message != "") 
		{
			this.$bvModal.show("modalMessage"); 
			return;
		}
		this.enquiry.listingId = this.listing.id;
		this.$http.post(this.$config.ApiUri + "/Customers/" + this.listing.customerId + "/Enquiries", this.enquiry)
			.then(() => {
				this.$bvModal.hide("modalEnquire");
			}
		)
	},
	onLoad() {
		this.getListing()
		this.getSelf();
	}
  },
  mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

	.body
	{
		background-color: #b6b1a6;
		m--argin:-20px;
	}

	.colorBlock
	{
		b-ackground-color: rgb(142, 164, 135);
		background-color: #EBB561;
		text-align: center;
		font-family: GrifintolL, 'Gloock', serif !important;
		font-size: 24px;
		line-height: 1.2;
		padding: 25px;
		padding-bottom: 20px;
		padding-top: 20px;
	}

	.enquireBlock {
		background-color: rgb(248, 248, 248);
		padding-bottom: 3px;
		padding-top: 3px;
		text-align: center;
	}

	.enquireBlock .btn-link {
		margin-top: 0px !important;
		font-size: 25px;
		font-size: 15px !important;
		text-transform: uppercase;
	}

	.rounded-top
	{
		border-top-left-radius: 0.5rem !important;
		border-top-right-radius: 0.5rem !important;
	}
	.listingResult
	{
		padding:0px;
		margin-top: 0px;
	}
	.listingResult .title
	{
		text-transform: uppercase;
		color: black;
		font-family: GrifintolL, 'Gloock', serif !important;
		font-size: 35px;
	}
	.listingResult img
	{
		
	}
	.listingResult .priceInfo
	{
		padding: 10px;
		padding-left: 15px;
		background-color: #b6b1a6;
		color: white;
		border:0px;
	}
	.listingResult .login-prompt
	{
		border:0px;
		padding: 10px;
		padding-left: 15px;
		padding-bottom: 20px;
		background-color: #b6b1a6;
		color: white;	
	}
	.listingResult .info
	{
		border:0px;
		padding: 10px;
		padding-left: 15px;
		background-color: #ffffff;
		color: black;		
	}
	.listingResult .info .address{
		font-weight: bold;
	}
	.listingResult .info .feature{
		margin-right: 20px;
	}
	.listingResult .info .favourite{
		margin-top: 5px;
		text-align: right;
	}

	.favourite {
		position: fixed !important;
		top: 30px !important;
		right: 30px !important;
		border-radius: 50% !important;
		background-color: white !important;
		z-index: 1000;
		width: 45px;
		height: 45px;
	}

	.favourite svg {
		padding-top: 3px;
	}

	.btn-link
	{
		color: #000000 !important;
	}

	.listingResult .actions
	{
		border:0px;
		background-color: #ffffff;
		padding: 15px;
		border-bottom-left-radius: 0.5rem !important;
		border-bottom-right-radius: 0.5rem !important;
		b-ox-shadow: 0px 1px 0px 0px rgba(172, 172, 172, 0.75);
	}
	a{
		color: inherit;
		text-decoration: inherit;
	}
	h4
	{
		margin-top: 10px !important;
		font-family: GrifintolL, 'Gloock', serif !important;
		font-size: 27px !important;
		text-transform: capitalize;
	}
	strong
	{
		font-family: GrifintolL, 'Gloock', serif !important;
		font-size: 23px !important;
		font-weight: normal;
		clear:both
	}

</style>