<template>
<div id="fullWidthContent" class="filledBg" :style="'min-height:' + this.$parent.heightAfterHeaderFooter + 'px'">

	<b-button class="backNav" @click="$router.back()" size="xs" variant="link"><b-icon-caret-left-fill /></b-button>

	<div class="margined">
		<h2>{{ collection.name }}</h2>
		<b-form>
			
			<b-form-group label="Name:">
				<b-form-input v-model="collection.name" type="text" required></b-form-input>
			</b-form-group>

			<b-form-group label="Image URL:">
				<b-form-input v-model="collection.image" type="text" required></b-form-input>
			</b-form-group>

			<b-form-group label="Description:">
				<b-form-textarea rows="4" v-model="collection.description" type="text" required></b-form-textarea>
			</b-form-group>

			<div class="map">
				<GmapMap :options="{
						zoomControl:true,  
						mapTypeControl: false,
						scaleControl: true,
						streetViewControl: true,
						rotateControl: false,
						fullscreenControl: true,
						disableDefaultUi: false
					}" 
					:center="{ lat:parseFloat(collection.lat), lng:parseFloat(collection.lng) }" 
					:zoom="collection.mapZoom"
					@zoom_changed="mapZoomed"
					style="width: 100%; height: 350px" 
				>
				<GmapMarker
					:key="index"
					:position="{ lat:parseFloat(collection.lat), lng:parseFloat(collection.lng) }"
					:draggable=true
					@dragend="markerDragged"
					/>
				</GmapMap>
			</div>

			<div>
				<b-form-group id="input-group-1" label-for="enabled">
					<b-form-checkbox v-model="collection.enabled" name="approved">&nbsp;Enabled</b-form-checkbox>
				</b-form-group>
			</div>
				
		</b-form>
		<br/>
		<b-button @click="save">Save</b-button> <b-button v-if="collection.id" @click="deleteCollection" variant="danger">Delete</b-button>

	</div>

	<b-modal id="modalMessage" centered title="Create Area Guide" hide-header="true">
		<h2>Please fix the following:</h2>
		<p class="my-4" v-html="message"></p>
		<template #modal-footer>
			<!-- Emulate built in modal footer ok and cancel button actions -->
			<b-button size="" variant="primary" @click="hideModal">
				OK
			</b-button>
		</template>
	</b-modal>

</div>
</template>
<script>

export default {
  name: 'CollectionAdmin',
  components: {

  },
  data() {
	return{
		collection: null
	}
  },
  methods: {
	hideModal: function() {
		this.$bvModal.hide("modalMessage");
	},
	markerDragged: function(location) {
		this.collection.lat = location.latLng.lat();
		this.collection.lng = location.latLng.lng();
	},
	mapZoomed: function(z) {
		this.collection.mapZoom = z;
	},
	getCollection: function(){
		this.$http.get(this.$config.ApiUri + "/Collections/" + this.$route.params.id + "?lite=true")
			.then((result) => {
				this.collection = result.data;
				if (this.collection.lat == null) {
					this.collection.lat = 0;
					this.collection.lat = 0;
					this.collection.mapZoom = 10;
				}
			}
		)
	},
	save: function() {
		if (this.collection.id == null) {
			this.$http.post(this.$config.ApiUri + "/Collections/", this.collection)
				.then((result) => {
					this.collection = result.data;
					this.$router.back();
				}
			)
		}
		else {
			this.$http.put(this.$config.ApiUri + "/Collections/" + this.collection.id, this.collection)
				.then((result) => {
					this.collection = result.data;
					this.$router.back();
				}
			)
		}
	},
	deleteCollection: function() {
		this.$http.delete(this.$config.ApiUri + "/Collections/" + this.collection.id)
			.then(() => {
				this.$router.back();
			}
		)
	},
	onLoad() {
		if (this.$route.params.id != '-') {
			this.getCollection();
		}
		else {
			this.collection = { "name": "", "lat": 0, "lng": 0, "mapZoom": 1 };
		}
	}
  },
  mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

.btn-link
{
	color: #000000 !important;
}

</style>