<template>
<div id="fullWidthContent" class="filledBg">

	<div class="margined" v-if="members">
		<b-form-input placeholder="search" class="mb-2" v-model="search"></b-form-input>
		{{ members.length }} member(s)
		<b-table striped hover :filter="search" :items="members" :fields="[
			{key: 'approved', label: 'Approved'},
			{key: 'person.firstName', label: 'Name'},
			{key: 'person.lastName', label: 'Surname'},
			{key: 'person.email', label: 'Email'},
			{key: 'person.mobilePhone', label: 'Phone'},
			{key: 'person.regReason', label: 'Reason'},
			{key: 'person.city', label: 'City'},
			{key: 'person.insta', label: 'Insta'},
			{key: 'person.linkedIn', label: 'LinkedIn'},
			{key: 'createDateTime', label: 'Registered'},
		]" selectable select-mode="single" @row-clicked="selectCustomer"></b-table>
	</div>

	<b-modal v-if="member" id="modalMessage" centered title="Approve Member" :hide-header="true">
		<h2>Approve {{member.person.firstName}}?</h2>
		<template #modal-footer>
			<!-- Emulate built in modal footer ok and cancel button actions -->
			<b-button size="" variant="primary" @click="approve">
				OK
			</b-button>
		</template>
	</b-modal>

</div>
</template>
<script>
export default {
  name: 'Members',
  metaInfo() {
        return { 
            title: "Three Degrees Admin - Members",
        }
    },
  components: {
  },
  data() {
	return{
		search: null,
		members: null,
		member:null
	}
  },
  methods: {
	approve: function() {
		this.$http.post(this.$config.ApiUri + "/Customers/" + this.member.id + "/ApprovalRequests")
			.then(() => {
				this.member.approved = true;
				this.hideModal();
			}
		)
	},
	hideModal: function() {
		this.$bvModal.hide("modalMessage");
	},
	showModal: function() {
		this.$bvModal.show("modalMessage");
	},
	getMembers: function(){
		this.$http.get(this.$config.ApiUri + "/Customers")
			.then((result) => {
				this.members = result.data;
			}
		)
	},
	selectCustomer: function(c) {
		this.member = c;
		this.showModal();
	},
	onLoad() {
		this.getMembers();
	},
  },
  mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

.mu-plus-icon
{
	color: #333333 !important;
}

</style>