<template>
<div id="fullWidthContent" class="filledBg" :style="'min-height:' + this.$parent.heightAfterHeader + 'px'">
	<b-button class="backNav" @click="$router.back()" size="xs" variant="link"><b-icon-caret-left-fill /></b-button>

	<div v-if="posts && posts.length == 0" class="margined centered">
		<p class="margin-side">There are no forum posts yet!</p>
	</div>
	<div :class="'post' + ((index % 4) + 1)" v-for="(m,index) in posts" :key="m.id">
			<b-row>
				<b-col class="avatar">
					<b-avatar :href="'#/members/' + m.customerId" :src="m.customerProfileImage" :text="m.customerName[0]" size="50px"></b-avatar><br/>
				</b-col>
				<b-col class="info">
					{{ m.customerName }}<br/>
					{{ m.createDateTimeString.replace("-", "at") }}
				</b-col>
			</b-row>
			<b-row class="subject">
				<b-col>
					{{ m.subject }}
				</b-col>
			</b-row>
			<b-row class="body">
				<b-col>
					<div v-html="m.message.split('\n').join('<br>')"></div>
				</b-col>
			</b-row>
			<b-row class="tools">
				<b-col>
					<!-- <b-button variant="success" @click="post.subject = m.subject"><b-icon flip-h icon="reply"></b-icon></b-button>&nbsp;
					<b-button v-if="m.customerId == self.id || admin" variant="warning" @click="post.subject = m.subject; post.message = m.message; deletePost(m.id)"><b-icon icon="textarea-t"></b-icon></b-button>&nbsp; -->
					<b-button v-if="m.customerId == self.id || admin" variant="danger" @click="deletePost(m.id)"><b-icon icon="trash"></b-icon></b-button>
				</b-col>
			</b-row>
		
	</div>

	<div ref="lastMessage" style="margin-bottom: 180px;"></div>

	<div class="send-area" ref="sendArea">
		<b-input-group>
			<b-form-input @focus="forceScroll()" name="subject" id="subject"
				data-lpignore="true" autocomplete="false" 
				v-model="post.subject" placeholder="subject">
			</b-form-input>
		</b-input-group>
		<b-input-group>
			<b-form-textarea rows="5" @focus="forceScroll()" name="message" id="message"
				data-lpignore="true" autocomplete="false" 
				v-model="post.message">
			</b-form-textarea>
			<template #append>
				<b-button :disabled="post.message == '' || posting" @click="sendMessage" variant="success"><b-icon icon="play-fill"></b-icon></b-button>
			</template>
		</b-input-group>
	</div>

</div>
</template>
<script>
import Services from '../mixins/Services';
export default {
  name: 'Forum',
  mixins: [Services],
  metaInfo() {
        return { 
            title: "Three Degrees - Forum",
        }
    },
  components: {

  },
  data() {
	return{
		posts: null,
		posting: false,
		interval:null,
		post: {
			message: "",
			subject: ""
		},
		admin: false
	}
  },
  methods: {

	sendMessage: function() {
		if (this.post.message == "" || this.post.message == null) { return; }
		if (this.post.subject == "" || this.post.subject == null) { return; }
		this.post.customerId = this.self.id;
		this.posting = true;
		this.$http.post(this.$config.ApiUri + "/ForumPosts", this.post)
			.then(() => {
				this.getPosts();
				this.post.message = "";
				this.post.subject = "";
				this.posting = false;
			}
		)
	},

	deletePost: function(id) {
		this.$http.delete(this.$config.ApiUri + "/ForumPosts/" + id)
			.then(() => {
				this.getPosts();
			}
		)
	},
	
	getMember: function(){
		this._getSelf(null, () => {
			this.post.customerName = this.self.person.firstName;
			this.post.customerProfileImage = this.self.profilePicture;
			this.getPosts();
		});
		if (this.$route && this.$route.meta && this.$route.meta.admin) {
			this.admin = true;
		}
	},

	forceScroll: function() {
		setTimeout(() => 
			this.$refs["lastMessage"].scrollIntoView({
				behavior: 'smooth',
			})
		, 200);
	},
	
	getPosts: function(){
		this.$http.get(this.$config.ApiUri + "/ForumPosts")
			.then((result) => {
				this.posts = result.data;
				this.forceScroll();
			}
		).catch((e) => { if (e.response.status == 401) { this.$router.push('login'); } })
	},

	onLoad() {
		this.getMember();
		this.interval = setInterval(() => this.getPosts(), 30000);
	},

	onUnLoad() {
		clearInterval(this.interval);
	}

  },

  mounted: function() { this.onLoad(); },
  destroyed:  function() { this.onUnLoad(); }

}
</script>

<style scoped>

.send-area
{
	bottom:0px;
	margin:10px;
	position: fixed;
	width: 95%;
	z-index: 9999;
	max-width: 980px;
}

.avatar
{
	padding-left: 40px;
}

.info {
	text-align: right;
	font-style: italic;
}

.subject {
	margin-top: 30px;
	padding-left: 50px;
	font-weight: bold;;
}

.body {
	margin-top: 20px;
	padding-left: 50px;
}

.tools
{
	text-align: right;
	margin-top: 20px;
}

.badge-secondary {
    color: #fff;
    background-color: #b6b1a6;
}

.post1
{
	padding: 30px;
	background-color: rgb(232, 105, 62);
	padding-left: 0px;
	color: white;
}

.post2
{
	padding: 30px;
	padding-left: 0px;
	background-color: rgb(142, 164, 135);
	color: black;
}

.post3
{
	padding: 30px;
	padding-left: 0px;
	background-color: #EBB561;
	color: black;
}

.post4
{
	padding: 30px;
	padding-left: 0px;
	background-color: #143748;
	color: white;
}

.btn-link
{
	color: #000000 !important;
}

	
</style>